/* eslint-disable no-param-reassign */
import { doGraphqlRequest } from 'ducks/requests/operations'
import { MAILBOX_CHANNEL_TYPE } from 'ducks/folders/constants'
import { buildId } from 'util/globalId'
import { camelize } from 'util/strings'
import { FETCH_CONVERSATION_EVENT_GROUPS } from '../actionTypes'
import { conversationEventGroupsGraphQlResponseSchema } from '../schema'

import { buildConversationEventGroupsRequestKey } from '../utils/request'
import { ticketEventGroupsQuery } from '../queries'

const transformAttachEventGroupIdToEvents = data => {
  if (data.eventGroups) {
    data.eventGroups.edges.forEach((eg, index) => {
      eg.node.isFirstMessage = index === 0
      eg.node.isLastMessage = index === data.eventGroups.edges.length - 1
      eg.node.events.edges.forEach(e => {
        e.node.eventGroupId = eg.node.id
      })
    })
  }
  return data
}

/*
  This method will be used to retrieve the conversations
  Currently there is a massive disparity in the api implementation between tickets and
  rooms. This method will hide those differences and return a standadised interface for
  the folder component.
 */
export function doFetchTicketEventGroups(ticketId, options = {}) {
  return async dispatch => {
    if (!ticketId || ticketId === 'new') return null
    const conversationId = buildId('Conversation', ticketId)
    const filter = {
      conversationId,
    }

    return dispatch(
      doGraphqlRequest(
        FETCH_CONVERSATION_EVENT_GROUPS,
        ticketEventGroupsQuery(),
        {
          filter,
        },
        {
          ...options,
          app: true,
          normalizationSchema: conversationEventGroupsGraphQlResponseSchema,
          transformResponse: transformAttachEventGroupIdToEvents,
          meta: {
            channelType: MAILBOX_CHANNEL_TYPE,
            requestKey: camelize(
              buildConversationEventGroupsRequestKey(ticketId)
            ),
            mergeEntities: true,
          },
          throwOnError: true,
        }
      )
    )
  }
}
