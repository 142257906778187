/* eslint-disable no-param-reassign */
import { doGraphqlRequest } from 'ducks/requests/operations'
import debug from 'util/debug'
import { MAILBOX_CHANNEL_TYPE } from 'ducks/folders/constants'
import { buildId } from 'util/globalId'
import { camelize } from 'util/strings'
import { FETCH_CONVERSATION } from '../actionTypes'
import { conversationGraphQlResponseSchema } from '../schema'

import { buildConversationRequestKey } from '../utils/request'
import { ticketConversationQuery } from '../queries'

/*
  This method will be used to retrieve the conversations
  Currently there is a massive disparity in the api implementation between tickets and
  rooms. This method will hide those differences and return a standadised interface for
  the folder component.
 */
export function doFetchTicket({
  conversationId: inputConversationId,
  channelType,
  options = {},
}) {
  return async dispatch => {
    if (!inputConversationId || inputConversationId === 'new') return null
    const conversationId = buildId('Conversation', inputConversationId)
    if (channelType !== MAILBOX_CHANNEL_TYPE) {
      debug(
        `doFetchTicket currently only supports loading the ${MAILBOX_CHANNEL_TYPE} channel type`
      )
    }

    return dispatch(
      doGraphqlRequest(
        FETCH_CONVERSATION,
        ticketConversationQuery(),
        {
          conversationId,
        },
        {
          ...options,
          app: true,
          normalizationSchema: conversationGraphQlResponseSchema,
          meta: {
            channelType,
            requestKey: camelize(
              buildConversationRequestKey(inputConversationId)
            ),
            mergeEntities: true,
            updateSearches: true,
          },
          throwOnError: true,
        }
      )
    )
  }
}
