import { mergePlain } from 'util/merge'
import { doGraphqlRequest } from 'ducks/requests/operations'
import { buildId, getRawId } from 'util/globalId'
import { withUndoSend } from 'ducks/undoSend/request'
import { CONVERSATION_NEW } from '../actionTypes'
import { updateConversationNewMutation } from '../mutations'
import {
  CONVERSATION_SAVE_UNLOAD_MESSAGE,
  NEW_CONVERSATION_ID,
  SNOOZED,
} from '../constants'
import { conversationCreateEmailGraphQlResponseSchema } from '../schema'
import {
  withAutoRedirect,
  onUpdateAttachEventGroupIdToEvents,
  withBeforeSend,
} from '../utils/request'
import { emailArrayToEmailRecipientInputArray } from '../utils/payload'

export const doCreateEmail = (
  body,
  to,
  cc,
  bcc,
  subject,
  attachments,
  cannedReplyId,
  draftId,
  inputState,
  snoozeUntil,
  tagIdsToAdd,
  inputAgentId,
  inputTeamId,
  inputChannelId,
  options = {}
) => async dispatch => {
  const ticketId = NEW_CONVERSATION_ID
  const agentId = buildId('Agent', inputAgentId)
  const teamId = buildId('Team', inputTeamId)
  const channelId = buildId('Channel', inputChannelId)

  const state = snoozeUntil ? SNOOZED : inputState
  if (!body || !to || !to[0]) return null

  return dispatch(
    withUndoSend(
      draftId,
      withBeforeSend(
        ticketId,
        draftId,
        withAutoRedirect(
          ticketId,
          doGraphqlRequest(
            CONVERSATION_NEW,
            updateConversationNewMutation(),
            {
              assigned: {
                agentId,
                teamId,
              },
              channelId,
              message: {
                subject,
                body,
                to: emailArrayToEmailRecipientInputArray(to),
                cc: emailArrayToEmailRecipientInputArray(cc),
                bcc: emailArrayToEmailRecipientInputArray(bcc),
                attachments,
              },
              state,
              cannedReplyId,
              draftId,
              snoozeUntil,
              tagIds: tagIdsToAdd,
              skipNotifications: !!options.skipNotifications,
            },
            mergePlain(
              {
                app: true,
                throwOnError: true,
                concurrency: {
                  key: ticketId,
                  message: CONVERSATION_SAVE_UNLOAD_MESSAGE,
                },
                normalizationSchema: conversationCreateEmailGraphQlResponseSchema,
                transformResponse: onUpdateAttachEventGroupIdToEvents(
                  'conversationCreateEmail'
                ),
                moduleOptions: {
                  snackbar: {
                    enabled: true,
                    started: {
                      enabled: true,
                      message: 'Creating your reply...',
                    },
                    success: {
                      enabled: false,
                    },
                    failed: {
                      enabled: true,
                      message: `Oops, we couldn't create your conversation. Please try again in a moment.`,
                    },
                  },
                },
                meta: {
                  mergeEntities: true,
                },
              },
              options
            )
          ),
          mergePlain(
            {
              moduleOptions: {
                autoRedirect: {
                  extractTicketId: response => {
                    return getRawId(
                      response?.conversationCreateEmail?.conversation?.id
                    )
                  },
                  forced: true,
                },
              },
            },
            options
          )
        )
      )
    )
  )
}
