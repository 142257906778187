import { selectChannelCustomFields } from 'ducks/crm/channels/selectors/selectChannelCustomFields'
import { selectCurrentAction } from 'selectors/location'
import {
  addQueryConfig,
  customFieldKeyToSearchKey,
  mapToCustomField,
} from '../utils/query'

export const doAddCustomFieldsToQueryConfig = () => (dispatch, getState) => {
  const channelCustomFields = selectChannelCustomFields(getState())
  const customFieldsGroupedBySearchKey = channelCustomFields.reduce(
    (acc, cf) => {
      const searchKey = customFieldKeyToSearchKey(cf.key)
      // eslint-disable-next-line no-param-reassign
      if (!acc[searchKey]) acc[searchKey] = []

      acc[searchKey].push(cf)
      return acc
    },
    {}
  )
  let baseIndex = 5000
  Object.keys(customFieldsGroupedBySearchKey).forEach(searchKey => {
    const customFieldIds = customFieldsGroupedBySearchKey[searchKey].map(
      cf => cf.id
    )
    addQueryConfig({
      id: searchKey,
      urlAliases: [searchKey],
      keyToFilter: mapToCustomField(customFieldIds),
      type: 'filter',
      order: baseIndex,
      allowedValues: '*',
      allowMultiple: false,
    })
    baseIndex += 1
  })
  return dispatch(selectCurrentAction(getState()))
}
