/* eslint-disable no-param-reassign */
import { BEGIN } from 'redux-optimist'
import { MAILBOX_CHANNEL_TYPE } from 'ducks/folders/constants'
import { createActionTypeReducer } from 'util/reducers'
import { without, withUnshift, withPush, difference } from 'util/arrays'
import {
  normalizeSearchQueryId,
  constructGraphQLFilterObject,
  constructGraphQLOrderByObject,
  removeContextFromQueryId,
  isForCurrentUser,
  removeKeysFromQueryId,
  queryIdToQuery,
  toBaseQueryId,
} from 'ducks/searches/utils/query'
import debug from 'util/debug'
import { deepCopy, emptyObj } from 'util/objects'
import { byLongestUnanswered, byOldest } from 'util/search/sorting'
import {
  ROOM_OPTIMISTIC_UPDATED,
  ROOMS_UPDATED,
  ROOM_DELETE_STARTED,
} from 'ducks/chat/actionTypes/rooms'

import { getRoomId } from 'ducks/chat/utils/rooms'
import { getRawId } from 'util/globalId'
import { DELETE_CONVERSATION_STARTED } from 'ducks/tickets/actionTypes'
import { DELETE_MODE_HARD } from 'ducks/tickets/constants'

import {
  SEARCH_CONVERSATIONS_STARTED,
  SEARCH_CONVERSATIONS_SUCCESS,
  SEARCH_CONVERSATIONS_FAILED,
  FETCH_CONVERSATION_COUNTS_FOR_CHANNEL_SUCCESS,
  SEARCH_SYNC,
} from '../actionTypes'
import { calculateBasicDiff, normalizeConversation } from '../utils/diff'

const byQueryIdInitialState = {}

function createDefaultQuery(queryId) {
  const orderBy = constructGraphQLOrderByObject(queryId) || {}
  const defaultQ = {
    queryId,
    entityIds: [],
    removedEntityIds: [],
    addedEntityIds: [],
    // highlights: {},
    filter: constructGraphQLFilterObject(queryId),
    orderBy,
    cursors: {},
    currentPageCursor: null,
    previousPageCursor: null,
    nextPageCursor: null,
    loading: false,
    loaded: false,
    errored: false,
    error: null,
  }
  return defaultQ
}

function storeQueryEntityCount({
  queryType,
  channelId,
  draftState,
  countKey = 'entityCount',
  channelType = 'widget',
}) {
  return ({ id, [countKey]: entityCount }) => {
    let queryId = `type:${channelType} ${queryType}${id}`
    if (channelId) {
      queryId = `channel:${channelId} ${queryId}`
    }
    queryId = normalizeSearchQueryId(queryId)
    if (!draftState[queryId]) {
      draftState[queryId] = createDefaultQuery(queryId)
    }
    Object.assign(draftState[queryId], {
      entityCount,
    })
  }
}

function findLastCursor(cursors) {
  return Object.values(cursors).find(cursor => !cursor.hasNextPage)
}

function findFirstCursor(cursors) {
  return Object.values(cursors).find(cursor => !cursor.hasPreviousPage)
}

function cursorsWithout(cursors, entityId) {
  Object.values(cursors).forEach(cursor => {
    without(cursor.entityIds, entityId)
  })
}

function withUnshiftToFirstCursor(cursors, entityId) {
  const firstCursor = findFirstCursor(cursors)
  if (firstCursor) {
    withUnshift(firstCursor.entityIds, entityId)
  }
}

function withPushToLastCursor(cursors, entityId) {
  const lastCursor = findLastCursor(cursors)
  if (lastCursor) {
    withPush(lastCursor.entityIds, entityId)
  }
}

function entityIdsFromCursors(cursors) {
  return Object.values(cursors).reduce((eIds, { entityIds }) => {
    entityIds.forEach(entityId => withPush(eIds, entityId))
    return eIds
  }, [])
}

function shouldAppendTicketToEnd(sortOrder) {
  return byOldest(sortOrder) || byLongestUnanswered(sortOrder)
}

const reducers = {}

reducers[FETCH_CONVERSATION_COUNTS_FOR_CHANNEL_SUCCESS] = (
  draftState,
  action
) => {
  const {
    payload,
    meta: {
      requestParameters: { channelId },
      channelType,
    },
  } = action

  const {
    folders: { nodes: folderCounts } = {},
    tags: { nodes: tagCounts } = {},
    agents: { nodes: agentCounts } = {},
    channels: { nodes: channelCounts } = {},
    pinnedSearches: { nodes: pinnedSearchCounts } = {},
  } = payload

  folderCounts.forEach(
    storeQueryEntityCount({
      queryType: 'folder:',
      draftState,
      channelId,
      countKey: 'conversationCount',
      channelType,
    })
  )

  folderCounts.forEach(
    storeQueryEntityCount({
      queryType: 'folderunread:',
      draftState,
      channelId,
      countKey: 'unreadCount',
      channelType,
    })
  )

  tagCounts.forEach(
    storeQueryEntityCount({
      queryType: 'is:open tagid:',
      draftState,
      channelId,
      countKey: 'conversationCount',
      channelType,
    })
  )

  agentCounts.forEach(
    storeQueryEntityCount({
      queryType: 'is:open assignee:',
      draftState,
      channelId,
      countKey: 'conversationCount',
      channelType,
    })
  )

  if (pinnedSearchCounts) {
    const savePinnedSearchCount = storeQueryEntityCount({
      queryType: '',
      draftState,
      countKey: 'conversationCount',
      channelType,
      channelId,
    })

    pinnedSearchCounts.forEach(({ conversationCount, queryId }) => {
      savePinnedSearchCount({ id: queryId, conversationCount })
    })
  }

  if (!channelId) {
    channelCounts.forEach(
      storeQueryEntityCount({
        queryType: 'channel:',
        draftState,
        countKey: 'conversationCount',
        channelType,
      })
    )
    const allFolderCount = channelCounts.reduce((total, count) => {
      return total + count.conversationCount
    }, 0)
    storeQueryEntityCount({
      queryType: '',
      draftState,
      countKey: 'conversationCount',
      channelType,
    })({ id: '', conversationCount: allFolderCount })
  }
  return draftState
}

reducers[SEARCH_CONVERSATIONS_STARTED] = (
  draftState,
  {
    meta: {
      requestParameters: { queryId },
    },
  }
) => {
  if (!draftState[queryId]) {
    draftState[queryId] = createDefaultQuery(queryId)
  }
  Object.assign(draftState[queryId], {
    loading: true,
    errored: false,
  })
  return draftState
}

reducers[SEARCH_CONVERSATIONS_FAILED] = (
  draftState,
  {
    payload: { error },
    meta: {
      requestParameters: { queryId },
    },
  }
) => {
  if (!draftState[queryId]) {
    draftState[queryId] = createDefaultQuery(queryId)
  }
  Object.assign(draftState[queryId], {
    loading: false,
    errored: true,
    error,
  })
  return draftState
}

// Deprecated, should be replaced with a searches action
reducers[SEARCH_CONVERSATIONS_SUCCESS] = (
  draftState,
  {
    payload: {
      conversations: {
        nodes = [],
        pageInfo: { startCursor, endCursor, hasNextPage, hasPreviousPage } = {},
        totalCount,
        totalPageCount,
      } = {},
    },
    meta: {
      requestParameters: { queryId, cursor, orderBy },
    },
  }
) => {
  if (!draftState[queryId]) {
    draftState[queryId] = createDefaultQuery(queryId)
  }
  // The first page doesnt have a cursor, and so its value would be null
  const currentPageCursor = cursor || null
  const currentEntityIds = nodes.map(({ id }) => getRawId(id))

  Object.keys(draftState[queryId].cursors).forEach(cursorId => {
    // The current cursor will always be overwritten with the latest information
    // so ther eis no reason to apply a differential update
    if (cursorId === currentPageCursor) return

    const stateCursor = draftState[queryId].cursors[cursorId]
    currentEntityIds.forEach(entityId => {
      if (stateCursor.entityIds.includes(entityId)) {
        without(stateCursor.entityIds, entityId)
        stateCursor.isStale = true
      }
    })
  })

  const cursors = Object.assign(draftState[queryId].cursors, {
    [currentPageCursor]: Object.assign(
      draftState[queryId].cursors[currentPageCursor] || {},
      {
        current: currentPageCursor,
        next: endCursor,
        previous: startCursor,
        hasNextPage,
        hasPreviousPage,
        entityIds: currentEntityIds,
        // Has a realtime or optimistic update happened that
        // affected an entity in this page
        isStale: false,
      }
    ),
  })

  let entityCount = totalCount
  // When update conversations, there timestamps get updated which means they bubble to the top
  // of the conversation list. This means that when we load the first page in the default order
  // we can use the results to check if the recent changes has been indexed and clear the
  // addedConversations array which keeps track of local changes
  if (currentPageCursor === null && orderBy === null) {
    const { addedEntityIds, removedEntityIds } = draftState[queryId]
    // When we optimistically move conversations around we add them to addedEntityIds
    // and removedEntityIds, this means that if the customer quickly navigates to
    // the new folder before ES has been updated, we'll add/remove the conversation in the
    // list even if the latest respond from ES differs.
    currentEntityIds.forEach(conversationId => {
      without(addedEntityIds, conversationId)
      if (removedEntityIds.includes(conversationId)) {
        without(draftState[queryId].cursors[currentPageCursor], conversationId)
        entityCount -= 1
      }
    })
    entityCount += addedEntityIds.length
  }

  const entityIds = entityIdsFromCursors(draftState[queryId].cursors)

  Object.assign(draftState[queryId], {
    queryId,
    entityCount,
    currentPageCursor,
    nextPageCursor: endCursor,
    totalPages: totalPageCount,
    orderBy,
    loading: false,
    loaded: true,
    errored: false,
    // highlights,
    hasAllPages: draftState[queryId].hasAllPages || !hasNextPage,
    entityIds,
    cursors,
  })
  return draftState
}

reducers[SEARCH_SYNC] = (
  draftState,
  {
    payload: {
      currentLastUpdatedAt,
      lastUpdatedAt,
      searches,
      currentUser: { id: currentUserId },
      widgetsById,
      pageChannelType,
    },
  }
) => {
  // We can only start syncing counts after the initial counts has been loaded via
  // FETCH_CONVERSATION_COUNTS_FOR_CHANNEL
  if (!currentLastUpdatedAt) return
  // We're using ephemeral events in matrix which will automatically remove the
  // json content after the specified period (currently its 1 minute after creation)
  // The event still existing, but there is no content. If we recieve an event like
  // this we should just ignore it
  if (!lastUpdatedAt || !searches) return
  // If the data in our store is up to date or newer that the data getting loaded, then
  // just ignore this update
  if (currentLastUpdatedAt >= lastUpdatedAt) return

  Object.keys(draftState).forEach(rawQueryId => {
    const queryId = toBaseQueryId(rawQueryId)

    Object.keys(searches).forEach(rawSearchDiffQueryId => {
      // Remove the type:widget when we implement the unified conversation index
      if (!isForCurrentUser(rawSearchDiffQueryId, currentUserId)) return

      const { channelId } = queryIdToQuery(queryId) || {}
      let channelType = pageChannelType
      if (channelId && widgetsById) {
        const { channelType: widgetChannelType } =
          widgetsById[getRawId(channelId)] || {}
        channelType = widgetChannelType || channelType
      }

      const searchDiffQueryId = normalizeSearchQueryId(
        `${removeContextFromQueryId(rawSearchDiffQueryId)} type:${channelType}`
      )

      if (searchDiffQueryId !== queryId) return

      const searchDiff = searches[rawSearchDiffQueryId]
      const search = draftState[rawQueryId]

      const currentSearch = debug.enabled ? deepCopy(search) : null
      const { plus, minus } = searchDiff

      let increaseCountBy = searchDiff.plus.length
      let decreaseCountBy = searchDiff.minus.length

      // All the ticket ids we have cached on all pages
      const {
        entityIds,
        removedEntityIds,
        addedEntityIds,
        orderBy,
        cursors,
      } = search

      plus.forEach(conversationId => {
        // conversation is already in the list, no need to add
        if (
          entityIds.includes(conversationId) ||
          addedEntityIds.includes(conversationId)
        ) {
          // and also no need to change the search count for this conversation
          increaseCountBy -= 1
          without(addedEntityIds, conversationId)
        } else {
          // If sorting by oldest and we have *all* pages cached, then we
          // can simply add the new conversation to the end of the list. If we
          // dont have all pages cached, we cant know where to insert it,
          // so we do nothing.
          // eslint-disable-next-line no-lonely-if
          if (shouldAppendTicketToEnd(orderBy)) {
            // see if we have the last page cached...
            if (search.hasAllPages) {
              withPushToLastCursor(cursors, conversationId)
              without(removedEntityIds, conversationId)
            }
          } else {
            // ASSUMES ORDERED BY NEWEST
            withUnshiftToFirstCursor(cursors, conversationId)
            without(removedEntityIds, conversationId)
          }
        }
      })

      minus.forEach(conversationId => {
        const currentIndex = entityIds.indexOf(conversationId)
        if (currentIndex >= 0) {
          cursorsWithout(cursors, conversationId)
          without(addedEntityIds, conversationId)
          // ticket is present in removed, do not decrease count
        } else if (removedEntityIds.includes(conversationId)) {
          decreaseCountBy -= 1
        }
        without(removedEntityIds, conversationId)
      })

      search.entityCount += increaseCountBy
      search.entityCount -= decreaseCountBy
      search.entityIds = entityIdsFromCursors(search.cursors)

      if (search.entityCount < 0) {
        if (debug.enabled) {
          // eslint-disable-next-line no-console
          console.error('got negative search count', {
            queryId,
            currentSearch,
            updatedSearch: deepCopy(search),
            update: searchDiff,
          })
        }
        search.entityCount = 0
      }
    })
  })
}

const optimisticUpdate = (draftState, action) => {
  const {
    payload: { roomId, room, oldRoom, widgetsById },
  } = action

  const oldEntities = action.entities?.state?.current
  const searchDiffs = calculateBasicDiff(room, oldRoom, oldEntities)

  Object.keys(draftState).forEach(rawQueryId => {
    const queryId = toBaseQueryId(rawQueryId)

    Object.keys(searchDiffs).forEach(rawSearchDiffQueryId => {
      const { channelType } = widgetsById[getRawId(room.channelId)]
      const searchDiffQueryId = normalizeSearchQueryId(
        `${rawSearchDiffQueryId} type:${channelType}`
      )
      if (queryId !== searchDiffQueryId) return
      const search = draftState[rawQueryId]
      const countDiff = searchDiffs[rawSearchDiffQueryId]

      if (countDiff < 0) {
        cursorsWithout(search.cursors, roomId)
        without(search.addedEntityIds, roomId)
        withPush(search.removedEntityIds, roomId)
        search.entityCount += countDiff
        search.entityIds = entityIdsFromCursors(search.cursors)
      } else if (countDiff > 0 && !search.entityIds.includes(roomId)) {
        withUnshiftToFirstCursor(search.cursors, roomId)
        withPush(search.addedEntityIds, roomId)
        without(search.removedEntityIds, roomId)
        search.entityCount += countDiff
        search.entityIds = entityIdsFromCursors(search.cursors)
      }
    })
  })
  return draftState
}

reducers[ROOM_OPTIMISTIC_UPDATED] = optimisticUpdate

reducers[ROOMS_UPDATED] = (draftState, action) => {
  const {
    payload: { roomUpdates, loadingMatrixIds },
  } = action
  if (!roomUpdates) return draftState

  const eventsByRoom = roomUpdates.reduce((out, { room, events }) => {
    // If we're loading this room as part of a ROOMS_FETCH, then no resorting
    // rules should be applied as the ES order is authorative
    if (loadingMatrixIds.includes(room.roomId)) return out

    const roomId = getRoomId(room)
    const roomMessageEvents = events.filter(event => {
      const roomEvents = room.getLiveTimeline().getEvents()
      const lastEvent = roomEvents[roomEvents.length - 1]

      return (
        event.getType() === 'm.room.message' &&
        (!roomEvents.includes(event) || event === lastEvent)
      )
    })
    if (roomId && roomMessageEvents.length > 0) {
      out[roomId] = roomMessageEvents
    }
    return out
  }, {})

  // Break out early if we have nothing to update
  if (Object.keys(eventsByRoom).length === 0) return draftState

  Object.keys(draftState).forEach(queryId => {
    const { entityIds, orderBy, loaded, hasAllPages, cursors } = draftState[
      queryId
    ]
    if (loaded) {
      Object.keys(eventsByRoom).forEach(rawRoomId => {
        const roomId = parseInt(rawRoomId, 10)
        if (entityIds.includes(roomId)) {
          if (shouldAppendTicketToEnd(orderBy)) {
            // see if we have the last page cached...
            if (hasAllPages) {
              cursorsWithout(cursors, roomId)
              withUnshiftToFirstCursor(cursors, roomId)
              draftState[queryId].entityIds = entityIdsFromCursors(cursors)
            }
          } else {
            // ASSUMES ORDERED BY NEWEST
            cursorsWithout(cursors, roomId)
            withUnshiftToFirstCursor(cursors, roomId)
            draftState[queryId].entityIds = entityIdsFromCursors(cursors)
          }
        }
      })
    }
  })

  return draftState
}

reducers[ROOM_DELETE_STARTED] = (draftState, action) => {
  const {
    payload: { conversationId },
  } = action

  Object.keys(draftState).forEach(queryId => {
    const search = draftState[queryId]
    if (search.entityIds.includes(conversationId)) {
      const { cursors, removedEntityIds } = search
      cursorsWithout(cursors, conversationId)
      withPush(removedEntityIds, conversationId)
      search.entityIds = entityIdsFromCursors(search.cursors)
    }
  })
  return draftState
}

reducers[DELETE_CONVERSATION_STARTED] = (draftState, action) => {
  const {
    payload: { conversationIds, deleteMode },
  } = action
  if (deleteMode !== DELETE_MODE_HARD) return draftState

  Object.keys(draftState).forEach(queryId => {
    const search = draftState[queryId]
    conversationIds.forEach(conversationId => {
      if (search.entityIds.includes(conversationId)) {
        const { cursors, removedEntityIds } = search
        cursorsWithout(cursors, conversationId)
        withPush(removedEntityIds, conversationId)
        search.entityIds = entityIdsFromCursors(search.cursors)
      }
    })
  })
  return draftState
}

function searchStartedReducer(draftState, action) {
  const { searches: { queries = {} } = {} } = action || {}

  Object.keys(queries).forEach(rawQueryId => {
    const queryId = removeKeysFromQueryId(['cursor'], rawQueryId)
    const { type } = queries[rawQueryId]
    if (type === 'STARTED') {
      Object.assign(draftState[queryId], {
        loading: true,
        errored: false,
      })
    }
  })
  return draftState
}

function searchFailedReducer(draftState, action) {
  const { searches: { queries = {} } = {} } = action || {}

  Object.keys(queries).forEach(rawQueryId => {
    const queryId = removeKeysFromQueryId(['cursor'], rawQueryId)
    const { type, error } = queries[rawQueryId]
    if (type === 'FAILED') {
      Object.assign(draftState[queryId], {
        loading: false,
        errored: true,
        error,
      })
    }
  })
  return draftState
}

function searchInvalidateReducer(draftState, action) {
  const { searches: { queries = {} } = {} } = action || {}

  Object.keys(queries).forEach(rawQueryId => {
    const queryId = removeKeysFromQueryId(['cursor'], rawQueryId)
    const { type } = queries[rawQueryId]
    if (type === 'INVALIDATE') {
      Object.keys(draftState[queryId].cursors).forEach(cursorId => {
        draftState[queryId].cursors[cursorId].isStale = true
      })
    }
  })
  return draftState
}

function searchUpdateCursorReducer(draftState, action) {
  const { searches: { updateCursor } = {} } = action || {}

  if (updateCursor) {
    Object.keys(updateCursor).forEach(rawQueryId => {
      const updates = updateCursor[rawQueryId]
      const { cursor } = constructGraphQLFilterObject(rawQueryId)
      const queryId = removeKeysFromQueryId(['cursor'], rawQueryId)
      const draftCursor = draftState[queryId]?.cursors[cursor]
      if (draftCursor) {
        Object.assign(draftCursor, updates)
      }
    })
  }
  return draftState
}

function searchAddCursorEntityIdsReducer(draftState, action) {
  const { searches: { addCursorEntityIds } = {} } = action || {}

  if (addCursorEntityIds) {
    Object.keys(addCursorEntityIds).forEach(rawQueryId => {
      const entityIds = addCursorEntityIds[rawQueryId]

      if (entityIds?.length) {
        const { cursor } = constructGraphQLFilterObject(rawQueryId)
        const queryId = removeKeysFromQueryId(['cursor'], rawQueryId)
        const draftCursor = draftState[queryId]?.cursors[cursor]

        const existingEntityIds = draftCursor?.entityIds || []
        // no duplicates adds
        const uniqEntityIdsToAdd = difference(entityIds, existingEntityIds)

        if (uniqEntityIdsToAdd) {
          Object.assign(draftState[queryId], {
            entityIds: [...existingEntityIds, ...uniqEntityIdsToAdd],
          })
        }

        if (draftCursor && uniqEntityIdsToAdd) {
          Object.assign(draftCursor, {
            entityIds: [...existingEntityIds, ...uniqEntityIdsToAdd],
          })
        }
      }
    })
  }

  return draftState
}

function searchRemoveCursorEntityIdsReducer(draftState, action) {
  const { searches: { removeCursorEntityIds } = {} } = action || {}

  if (removeCursorEntityIds) {
    Object.keys(removeCursorEntityIds).forEach(rawQueryId => {
      const entityIds = removeCursorEntityIds[rawQueryId]

      if (entityIds?.length) {
        const { cursor } = constructGraphQLFilterObject(rawQueryId)
        const queryId = removeKeysFromQueryId(['cursor'], rawQueryId)
        const draftCursor = draftState[queryId]?.cursors[cursor]

        const existingEntityIds = draftCursor?.entityIds || []

        if (existingEntityIds) {
          Object.assign(draftState[queryId], {
            entityIds: existingEntityIds.filter(id => !entityIds.includes(id)),
          })
        }

        if (draftCursor && existingEntityIds) {
          Object.assign(draftCursor, {
            entityIds: existingEntityIds.filter(id => !entityIds.includes(id)),
          })
        }
      }
    })
  }

  return draftState
}

function searchInvalidateEntityReducer(draftState, action) {
  const { searches: { invalidateEntities } = {} } = action || {}

  if (invalidateEntities) {
    Object.keys(draftState).forEach(queryId => {
      const { entityType } = queryIdToQuery(queryId) || {}
      if (invalidateEntities.includes(entityType)) {
        Object.keys(draftState[queryId].cursors).forEach(cursorId => {
          draftState[queryId].cursors[cursorId].isStale = true
        })
      }
    })
  }
  return draftState
}

function searchSuccessReducer(draftState, action) {
  const { searches: { queries = {} } = {} } = action || {}
  Object.keys(queries).forEach(rawQueryId => {
    const queryId = removeKeysFromQueryId(['cursor'], rawQueryId)
    const {
      type,
      result: {
        nodes = [],
        pageInfo: { startCursor, endCursor, hasNextPage, hasPreviousPage } = {},
        totalCount,
        totalPageCount,
      } = {},
      request: { cursor, orderBy } = {},
    } = queries[rawQueryId]
    if (type !== 'SUCCESS') return

    // The first page doesnt have a cursor, and so its value would be null
    const currentPageCursor = cursor || null
    const currentEntityIds = nodes.map(item => item.id || item.node.id)

    Object.keys(draftState[queryId].cursors).forEach(cursorId => {
      // The current cursor will always be overwritten with the latest information
      // so ther eis no reason to apply a differential update
      if (cursorId === currentPageCursor) return

      const stateCursor = draftState[queryId].cursors[cursorId]
      currentEntityIds.forEach(entityId => {
        if (stateCursor.entityIds.includes(entityId)) {
          without(stateCursor.entityIds, entityId)
          stateCursor.isStale = true
        }
      })
    })

    const cursors = Object.assign(draftState[queryId].cursors, {
      [currentPageCursor]: Object.assign(
        draftState[queryId].cursors[currentPageCursor] || {},
        {
          current: currentPageCursor,
          next: endCursor,
          previous: startCursor,
          hasNextPage,
          hasPreviousPage,
          entityIds: currentEntityIds,
          // Has a realtime or optimistic update happened that
          // affected an entity in this page
          isStale: false,
        }
      ),
    })

    let entityCount = totalCount
    // When update conversations, there timestamps get updated which means they bubble to the top
    // of the conversation list. This means that when we load the first page in the default order
    // we can use the results to check if the recent changes has been indexed and clear the
    // addedConversations array which keeps track of local changes
    if (currentPageCursor === null && orderBy === null) {
      const { addedEntityIds, removedEntityIds } = draftState[queryId]
      // When we optimistically move conversations around we add them to addedEntityIds
      // and removedEntityIds, this means that if the customer quickly navigates to
      // the new folder before ES has been updated, we'll add/remove the conversation in the
      // list even if the latest respond from ES differs.
      currentEntityIds.forEach(conversationId => {
        without(addedEntityIds, conversationId)
        if (removedEntityIds.includes(conversationId)) {
          without(
            draftState[queryId].cursors[currentPageCursor],
            conversationId
          )
          entityCount -= 1
        }
      })
      entityCount += addedEntityIds.length
    }

    const entityIds = entityIdsFromCursors(draftState[queryId].cursors)

    Object.assign(draftState[queryId], {
      queryId,
      totalCount: entityCount,
      totalPages: totalPageCount,
      loading: false,
      loaded: true,
      errored: false,
      hasAllPages: draftState[queryId].hasAllPages || !hasNextPage,
      entityIds,
      cursors,
      previousPageCursor: startCursor,
      currentPageCursor,
      nextPageCursor: endCursor,
    })
  })

  return draftState
}

const searchObserverReducer = (draftState, action) => {
  const { searches = emptyObj } = action || {}
  if (searches === emptyObj) return draftState
  const queries = searches.queries || emptyObj

  Object.keys(queries).forEach(rawQueryId => {
    const queryId = removeKeysFromQueryId(['cursor'], rawQueryId)
    if (!draftState[queryId]) {
      draftState[queryId] = createDefaultQuery(queryId)
    }
  })
  // Each reducer will determine if if needs to execute based on the
  // action payload
  if (queries !== emptyObj) {
    searchStartedReducer(draftState, action)
    searchSuccessReducer(draftState, action)
    searchFailedReducer(draftState, action)
    searchInvalidateReducer(draftState, action)
  }
  searchUpdateCursorReducer(draftState, action)
  searchAddCursorEntityIdsReducer(draftState, action)
  searchRemoveCursorEntityIdsReducer(draftState, action)
  searchInvalidateEntityReducer(draftState, action)
  return draftState
}

const SEARCH_OPTIMIST_SUPPORTED_ENTITIES = ['conversation']

const searchOptimisticObserverReducer = (draftState, action) => {
  const runOptimistic =
    action.meta?.updateSearches || action.optimist?.type === BEGIN
  if (!runOptimistic) return draftState
  const entities =
    action?.optimist?.payload?.entities || action?.transformedEntities
  const oldEntities = action.entities?.state?.current
  if (!entities || !oldEntities) return draftState

  SEARCH_OPTIMIST_SUPPORTED_ENTITIES.forEach(entityType => {
    const newEntitiyStore = entities[entityType]
    const oldEntitiyStore = oldEntities[entityType]?.byId || {}

    if (!newEntitiyStore) return
    Object.keys(entities[entityType]).forEach(entityId => {
      const newEntitiy = normalizeConversation(
        newEntitiyStore[entityId],
        oldEntities
      )
      const oldEntity = normalizeConversation(
        oldEntitiyStore[entityId],
        oldEntities
      )

      const searchDiffs = calculateBasicDiff(newEntitiy, oldEntity, oldEntities)

      Object.keys(draftState).forEach(rawQueryId => {
        const queryId = toBaseQueryId(rawQueryId)

        Object.keys(searchDiffs).forEach(rawSearchDiffQueryId => {
          const searchDiffQueryId = normalizeSearchQueryId(
            `${rawSearchDiffQueryId} type:${MAILBOX_CHANNEL_TYPE}`
          )
          if (queryId !== searchDiffQueryId) return
          const search = draftState[rawQueryId]
          const countDiff = searchDiffs[rawSearchDiffQueryId]

          if (countDiff < 0) {
            cursorsWithout(search.cursors, entityId)
            without(search.addedEntityIds, entityId)
            withPush(search.removedEntityIds, entityId)
            search.entityCount += countDiff
            search.entityIds = entityIdsFromCursors(search.cursors)
          } else if (countDiff > 0 && !search.entityIds.includes(entityId)) {
            withUnshiftToFirstCursor(search.cursors, entityId)
            withPush(search.addedEntityIds, entityId)
            without(search.removedEntityIds, entityId)
            search.entityCount += countDiff
            search.entityIds = entityIdsFromCursors(search.cursors)
          }
        })
      })
    })
  })
  return draftState
}

reducers['*'] = [searchObserverReducer, searchOptimisticObserverReducer]

export const byQueryId = createActionTypeReducer(
  reducers,
  byQueryIdInitialState
)
