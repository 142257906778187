import {
  eventFragment,
  actorFragment,
  emailMessageFragment,
  widgetFragment,
  noteFragment,
  replyFragment,
  recipientFragment,
  forwardFragment,
  attachmentFragment,
  conversationFragment,
  widgetConversationFragment,
  authorFragment,
  customFieldValueFragment,
  allConversationFragments,
} from './fragments'

export const ticketEventsQuery = () => `
query TicketEvents($filter: EventsFilterInput!) {
  events(filter: $filter) {
    edges {
      node {
        ...eventFragment
      }
      __typename
    }
  }
}

${eventFragment()}
${actorFragment()}
${emailMessageFragment()}
${widgetFragment()}
${noteFragment()}
${replyFragment()}
${recipientFragment()}
${forwardFragment()}
${attachmentFragment()}
${authorFragment()}
`

export const ticketEventGroupsQuery = () => `
query TicketEventGroups($filter: EventGroupsFilterInput!) {
  eventGroups(filter: $filter) {
    edges {
      node {
        id
        collapsed
        fromMerge
        isNote
        isForward
        hasAttachments
        conversationId
        changesetId,
        actor {
          ...actorFragment
        }
        summary {
          id
          bodyPlainText
          editedAt
          __typename
        }
        events {
          edges {
            node {
              ...eventFragment
            }
            __typename
          }
        }
      }
    }
  }
}

${eventFragment()}
${actorFragment()}
${emailMessageFragment()}
${widgetFragment()}
${noteFragment()}
${replyFragment()}
${recipientFragment()}
${forwardFragment()}
${attachmentFragment()}
${authorFragment()}
`

export const ticketConversationQuery = () => `
query TicketConversationQuery($conversationId: ID!) {
  node(id: $conversationId) {
    ${allConversationFragments()}
  }
}

${conversationFragment()}
${widgetConversationFragment()}
${customFieldValueFragment()}
`
