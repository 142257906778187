import { all, any, uniq, product, emptyArr, compact } from 'util/arrays'
import uniqWith from 'lodash.uniqwith'
import isEqual from 'lodash.isequal'
import { toDate, diff as dateDiff } from 'util/date'
import { toInt } from 'util/ordinal'
import { eq, getOperatorFn, MATCH_TYPE_ALL } from 'util/operators'
import { isDeleted, isRead } from 'ducks/tickets/utils/state'
import {
  FOLDER_CONDITION_TYPES,
  MAILBOX_CHANNEL_TYPE,
} from 'ducks/folders/constants'
import { buildId, buildIdFromAny } from 'util/globalId'
import { emptyObj } from 'util/objects'
import { SNOOZED_INDEFINITELY } from 'util/snooze'

const CURRENT_USER_ID = '-1'

const equalsTrue = m => eq(m, true)

// Loose equality match
const isTrue = str => str == 'true' // eslint-disable-line eqeqeq

const priorityMap = {
  low: 1000,
  medium: 2000,
  high: 3000,
  urgent: 4000,
}

const statusMap = {
  unread: 1000, // Ticket
  open: 2000, // Room
  opened: 2000, // Ticket (Change to open)
  follow_up: 3000, // Ticket (Pending removal)
  pending: 4000, // Ticket (Change to snoozed)
  snoozed: 4000, // Room
  closed: 5000, // Ticket/Room
  spam: 6000, // Ticket/Room
  trash: 7000, // Room (Need to add for tickets)
}

const hoursSinceOperands = (then, value, gateCondition = true) => ({
  prop: dateDiff('hours', toDate(then), new Date()),
  value: toInt(value),
  gateCondition,
})

const hoursSinceStateOperands = (conversation, value, state = null) =>
  hoursSinceOperands(
    conversation.stateChangedAt,
    value,
    state ? conversation.state === state : true
  )

const secondsUntilOperands = (until, value, gateCondition = true) => ({
  prop: until ? dateDiff('seconds', new Date(), toDate(until)) : undefined,
  value: toInt(value),
  gateCondition,
})

const computeAssignmentType = (agentId, teamId) => {
  if (!!agentId && !!teamId) return 'both'
  if (agentId) return 'agent'
  if (teamId) return 'team'
  return null
}

export const normalizeConversation = (conversation, entitiyStore) => {
  if (!conversation) return null

  const tagsById = entitiyStore.tag?.byId || emptyObj
  // 1 to 1 port of the logic in index_room_service. Make sure you keep that version
  // insync if you change this version
  const {
    channelId: inputChannelId,
    channel,
    state: inputState,
    assignedType: inputAssignmentType,
    assignedAgentId: inputAssignedAgentId,
    assignedTeamId: inputAssignedTeamId,
    assigned,
    isStarred: inputIsStarred,
    starred: inputStarred,
    tagIds: inputTagIds,
    tags: inputTags,
    interactionCount,
    isTrash,
    channelType,
    snoozed,
    snoozedUntil: inputSnoozedUntil,
    updatedAt,
    assignedAt,
    lastUnansweredUserMessageAt,
    stateChangedByAgentId,
    stateChangedAt,
    stateUpdatedAt,
    counts: { interactions } = {},
    draftAgentIds: inputDraftAgentIds,
    drafts = [],
    // CONVERTME
    // Optimistic updates was never implemented for mentions in the old systemn. The GQLV2 api doesnt currently expose
    // the information required to make this work, so we'll come back to this one
    mentionAgentIds = [],
    // Tickets dont have a is rated folder, so these are purely for the chat system. We'll circle back once we're ready
    // to implement these
    isRated,
    lastRating,
  } = conversation
  const { agent: assignmentAgentId, team: assignmentTeamId, at: assignmentAt } =
    assigned || {}
  const { until: input2SnoozedUntil, by: { snoozedById } = {} } = snoozed || {}

  // This section reconsiles the field names between chat and tickets
  // START RECONSILE
  const state = inputState.toLowerCase()
  const channelId = buildIdFromAny('Channel', inputChannelId || channel)
  const assignedType =
    inputAssignmentType ||
    computeAssignmentType(assignmentAgentId, assignmentTeamId)
  const assignedAgentId = inputAssignedAgentId || assignmentAgentId || null
  const assignedTeamId = inputAssignedTeamId || assignmentTeamId || null
  const isStarred = inputIsStarred === undefined ? inputStarred : inputIsStarred
  const tagIds = inputTagIds || inputTags || emptyArr
  const draftAgentIds =
    inputDraftAgentIds === undefined
      ? compact(drafts.map(d => d.agent?.id || null))
      : inputDraftAgentIds

  const snoozeValue = inputSnoozedUntil || input2SnoozedUntil
  let snoozedUntil = !snoozeValue ? SNOOZED_INDEFINITELY : snoozeValue
  snoozedUntil = state === 'snoozed' ? snoozedUntil : null
  // END RECONSILE

  return {
    channelId,
    assignedType,
    state: state.toLowerCase(),
    draftAgentIds,
    mentionAgentIds,
    isRated,
    lastRating,
    assignedAgentId,
    assignedTeamId,
    isStarred,
    tagIds,
    interactionCount:
      interactionCount === undefined ? interactions : interactionCount,
    isTrash: isTrash === undefined ? isDeleted(conversation) : isTrash,
    channelType: channelType === undefined ? MAILBOX_CHANNEL_TYPE : channelType,
    snoozedUntil,
    updatedAt,
    assignedAt: assignedAt || assignmentAt || null,
    lastUnansweredUserMessageAt,
    labels: tagIds.map(tagId => tagsById[tagId]?.name),
    stateChangedByAgentId: stateChangedByAgentId || snoozedById,
    stateChangedAt: stateChangedAt || stateUpdatedAt,
    isRead: isRead(conversation),
  }
}

const getOperands = ({ param, value }, conversation, entityStore) => {
  const {
    state,
    isStarred,
    isTrash,
    channelType,
    snoozedUntil,
    stateChangedByAgentId,
    assignedAgentId,
    assignedTeamId,
    interactionCount,
    updatedAt,
    assignedAt,
    labels,
    lastUnansweredUserMessageAt,
  } = conversation
  switch (param) {
    case FOLDER_CONDITION_TYPES.STARRED:
      return { prop: isStarred, value: isTrue(value) }
    case FOLDER_CONDITION_TYPES.PRIORITY:
      return {
        prop: isStarred ? priorityMap.urgent : priorityMap.low,
        value: priorityMap[value.toLowerCase()],
      }
    case FOLDER_CONDITION_TYPES.STATUS: {
      // NOTE: I've removed the snoozed logic from this code. We should rather add
      // this hacky logic to the reducer add create a proper "snoozed" state
      return {
        prop: statusMap[state.toLowerCase()],
        value: statusMap[value.toLowerCase()],
      }
    }
    case FOLDER_CONDITION_TYPES.DELETED:
      return { prop: isTrash, value: isTrue(value) }
    case FOLDER_CONDITION_TYPES.CHANNEL:
      return { prop: channelType, value }
    case FOLDER_CONDITION_TYPES.SNOOZE_UNTIL: {
      const indefinitely = 999999999

      // When folder filter is snoozed indefinitely and conversation
      // is snoozed indefinitely
      if (value === 'indefinitely' && snoozedUntil === SNOOZED_INDEFINITELY)
        return { prop: indefinitely, value: indefinitely }

      // when folder condition is snoozed indefinitely and conversation
      // is snoozedUntil
      if (value === 'indefinitely')
        return secondsUntilOperands(snoozedUntil, indefinitely)

      // when folder condition is snoozed unil X and conversation is
      // snoozed indefinitely
      if (snoozedUntil === SNOOZED_INDEFINITELY) {
        return { prop: indefinitely, value }
      }

      // When neither folder condition or conversation is snoozed indefinitely
      return secondsUntilOperands(snoozedUntil, value)
    }
    case FOLDER_CONDITION_TYPES.SNOOZE_STATE: {
      if (state !== 'snoozed') {
        return { prop: 0, value: 1 }
        // value of null means "Anyone", so if we have anyone,
        // we return a preformatted pair that will work in operation
      } else if (!!stateChangedByAgentId && value === null) {
        return { prop: 1, value: 1 }
      } else if (stateChangedByAgentId === null && value === null) {
        return { prop: 0, value: 1 }
      }
      return { prop: stateChangedByAgentId, value }
    }
    case FOLDER_CONDITION_TYPES.ASSIGNED_AGENT: {
      const session = entityStore.session.byId.current
      let mappedValue = value
      if (value === CURRENT_USER_ID) mappedValue = session.user
      return {
        prop: assignedAgentId,
        value: mappedValue,
      }
    }
    case FOLDER_CONDITION_TYPES.ASSIGNED_GROUP:
      return { prop: assignedTeamId, value }
    case FOLDER_CONDITION_TYPES.INTERACTION_COUNT:
      return { prop: interactionCount, value }
    case FOLDER_CONDITION_TYPES.HOURS_SINCE_UPDATED:
      return hoursSinceOperands(updatedAt, value)
    case FOLDER_CONDITION_TYPES.HOURS_SINCE_STATUS_CHANGED:
      return hoursSinceStateOperands(conversation, value)
    case FOLDER_CONDITION_TYPES.HOURS_SINCE_OPEN:
      return hoursSinceStateOperands(conversation, value, 'open')
    case FOLDER_CONDITION_TYPES.HOURS_SINCE_PENDING:
      return hoursSinceStateOperands(conversation, value, 'pending')
    case FOLDER_CONDITION_TYPES.HOURS_SINCE_CLOSED:
      return hoursSinceStateOperands(conversation, value, 'closed')
    case FOLDER_CONDITION_TYPES.HOURS_SINCE_ASSIGNED:
      return hoursSinceOperands(assignedAt, value)
    case FOLDER_CONDITION_TYPES.HOURS_SINCE_LAST_UNANSWERED_USER_MESSAGE:
      return hoursSinceOperands(lastUnansweredUserMessageAt, value)
    case FOLDER_CONDITION_TYPES.LABELS:
      return { prop: labels.map(label => label.name), value }
    default:
      return { prop: conversation[param], value }
  }
}

// Determines if the given ticket matches the given filter condition
// Returns true/false
export const matchConversation = (condition, conversation, entityStore) => {
  const opFn = getOperatorFn(condition.operator)
  const { prop, value, gateCondition = true } = getOperands(
    condition,
    conversation,
    entityStore
  )
  return opFn(prop, value) && gateCondition
}

// Determines if the given ticket matches any/all (matchType) of the the given
// filter conditions
export const matchFilter = (filter, conversation, entityStore) => {
  const { conditions, matchType } = filter
  const matchFn = matchType === MATCH_TYPE_ALL ? all : any
  const matches = conditions.map(condition =>
    matchConversation(condition, conversation, entityStore)
  )
  return matchFn(equalsTrue, matches)
}

// This method deviates from the server implementation because we already know "who"
// we're generating this for. The net effect is that the server version will generate
// a folder search for each agent and this version only generate a folder search
// for the current agent
const computeFolderSearches = (conversation, filter, entityStore) => {
  const matchesFilter = matchFilter(filter, conversation, entityStore)
  if (matchesFilter) {
    const filters = [`folder:${filter.id}`]
    if (!conversation.isRead) {
      filters.push(`folderunread:${filter.id}`)
    }
    return filters
  }
  return []
}

const stringifySearches = searches => {
  return uniqWith(searches, isEqual)
    .map(search => {
      return (
        search
          .map(searchPart => {
            if (Array.isArray(searchPart)) return searchPart.join(' ')
            return searchPart
          })
          .join(' ')
          // Strip leading and trialing spaces
          .replace(/^\s+|\s+$/g, '')
          .replace(/\s+/, ' ')
      )
    })
    .filter(queryId => !!queryId)
    .reduce((combinedSearches, queryId) => {
      // eslint-disable-next-line no-param-reassign
      combinedSearches[queryId] = true
      return combinedSearches
    }, {})
}

const computeConversationSearches = (conversation, entityStore) => {
  if (!conversation) return {}
  // 1 to 1 port of the logic in index_room_service. Make sure you keep that version
  // insync if you change this version
  const {
    channelId,
    state,
    assignedType,
    assignedAgentId,
    assignedTeamId,
    draftAgentIds,
    mentionAgentIds,
    isStarred,
    isRated,
    lastRating,
    tagIds,
  } = conversation

  // Channel searches
  const channels = [null, `channel:${channelId}`]

  // State searches
  const states = []
  // eslint-disable-next-line default-case
  switch (state) {
    case 'unread':
      states.push(null, 'is:unread')
      break
    case 'open':
    case 'opened':
      states.push(null, 'is:open')
      break
    case 'snoozed':
      states.push(null, 'is:snoozed')
      break
    case 'closed':
      states.push(null, 'is:closed')
      break
    case 'spam':
      states.push(null, 'is:spam')
      break
    case 'deleted':
    case 'trash':
      states.push(null, 'is:deleted')
      states.push(null, 'is:trash')
      break
  }

  // Agent searches
  const agents = []
  const teams = []
  const unassigned = [null]

  switch (assignedType) {
    case 'both':
      agents.push(
        null,
        'is:assigned',
        `agent:${buildId('Agent', assignedAgentId)}`,
        `assignee:${buildId('Agent', assignedAgentId)}`
      )
      teams.push(null, 'is:assigned', `team:${buildId('Team', assignedTeamId)}`)
      break
    case 'agent':
      agents.push(
        null,
        'is:assigned',
        `agent:${buildId('Agent', assignedAgentId)}`,
        `assignee:${buildId('Agent', assignedAgentId)}`
      )
      break
    case 'team':
      teams.push(null, 'is:assigned', `team:${buildId('Team', assignedTeamId)}`)
      break
    default:
      agents.push(null, 'assignee:unassigned')
      teams.push(null, 'group:unassigned')
      unassigned.push(null, 'is:unassigned')
  }

  // Draft searches (not currently implemented)
  const drafts = []
  draftAgentIds.forEach(agentId => {
    drafts.push(`draft:${buildId('Agent', agentId)}`)
  })

  // Mention searches
  const mentions = []
  mentionAgentIds.forEach(agentId => {
    mentions.push(`mentions:${buildId('Agent', agentId)}`)
  })

  // Starred searches
  const starred = isStarred ? [null, 'is:starred'] : [null]
  // Unread searches (doesnt appear to be used)
  // const unread = !isRead ? [null, 'is:unread'] : [null]
  // Rating searches
  const ratings = isRated
    ? [null, 'is:rated', `rating:${lastRating.grade}`]
    : [null]
  // Tag searches
  const tags = [
    null,
    ...tagIds.map(tagId => `tag:${buildId('Tag', tagId)}`),
    ...tagIds.map(tagId => `tagid:${buildId('Tag', tagId)}`),
  ]

  // Folder searches
  const filters = Object.values(entityStore.folder.byId)
  const folders = filters
    .map(filter => computeFolderSearches(conversation, filter, entityStore))
    .flat()

  return stringifySearches(
    [].concat(
      product(product(channels, unassigned), states),
      product(product(channels, teams), states),
      product(product(channels, agents), states),
      product(product(channels, states), tags),
      product(product(channels, drafts), states),
      product(product(channels, starred), states),
      product(product(channels, mentions), states),
      product(product(channels, ratings), states),
      product(channels, folders)
    )
  )
}

export const calculateBasicDiff = (
  updatedConversation,
  currentConversation,
  entityStore
) => {
  const updatedSearches = computeConversationSearches(
    updatedConversation,
    entityStore
  )
  const currentSearches = computeConversationSearches(
    currentConversation,
    entityStore
  )
  const updatedQueryIds = Object.keys(updatedSearches)
  const currentQueryIds = Object.keys(currentSearches)
  const allQueryIds = uniq(updatedQueryIds.concat(currentQueryIds))
  const diff = {}
  allQueryIds.forEach(queryId => {
    const valueIs = currentSearches[queryId]
    const valueWillBe = updatedSearches[queryId]
    if (valueIs && !valueWillBe) diff[queryId] = -1
    if (!valueIs && valueWillBe) diff[queryId] = 1
  })
  return diff
}
