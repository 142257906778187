import { mergePlain } from 'util/merge'
import { doRequest } from 'ducks/requests/operations'
import { isFunction } from 'util/functions'
import { BULK_UPDATE_CONVERSATIONS } from '../actionTypes'

const BULK_UPDATE_KEY = 'tickets-bulk-update'
const MAX_BULK_CONCURRENCY = 4

export const doBulkUpdate = (
  ticketIds,
  changeAction,
  parametersArrayOrFunc,
  options = {}
) => async (dispatch, getState) => {
  const concurrencyKey = `${BULK_UPDATE_KEY}-${Date.now()}`
  if (ticketIds.length === 0) return null
  if (ticketIds.length === 1) {
    const ticketId = ticketIds[0]
    const parameters = isFunction(parametersArrayOrFunc)
      ? parametersArrayOrFunc(ticketId, getState)
      : parametersArrayOrFunc
    return dispatch(changeAction(ticketId, ...parameters, options))
  }
  return dispatch(
    doRequest(
      BULK_UPDATE_CONVERSATIONS,
      async () => {
        return Promise.all(
          ticketIds.map(ticketId => {
            const parameters = isFunction(parametersArrayOrFunc)
              ? parametersArrayOrFunc(ticketId, getState)
              : parametersArrayOrFunc

            return dispatch(
              changeAction(ticketId, ...parameters, {
                ...options,
                concurrency: {
                  key: `${concurrencyKey}-child`,
                  parentKey: concurrencyKey,
                  maxConcurrency: MAX_BULK_CONCURRENCY,
                },
                moduleOptions: {
                  snackbar: {
                    enabled: false,
                  },
                },
                autoRedirect: { enabled: false },
              })
            )
          })
        )
      },
      {},
      mergePlain(
        {
          throwOnError: true,
          concurrency: {
            key: concurrencyKey,
          },
          moduleOptions: {
            snackbar: {
              enabled: true,
              success: {
                enabled: true,
                message: `${app.t('N_Tickets', {
                  count: ticketIds.length,
                })} updated.`,
              },
              failed: {
                enabled: true,
                message: "Oops, we couldn't update those conversations",
              },
            },
          },
        },
        options
      )
    )
  )
}
