import { createSelector } from 'reselect'
import { selectCurrentTicketId } from 'ducks/tickets/selectors/selectCurrentTicketId'
import { selectPreferences } from 'ducks/currentUser/selectors/preferences/selectPreferences'
import { emptyObj } from 'util/objects'
import { createBasicSelector } from 'util/redux'

const selectBase = state => state.undoSend || emptyObj

export const selectUndoSendSafe = state => {
  const { changesetId } = state.undoSend
  if (!changesetId) return emptyObj
  return state.undoSend
}

export const selectSendingTicketId = state => state.undoSend.ticketId || null

// Backwards compatibility hotfix
export const selectSendingTicketIds = createSelector(
  selectSendingTicketId,
  id => [id]
)

export const selectCurrentTicketIsDelayed = createBasicSelector(
  selectCurrentTicketId,
  selectSendingTicketId,
  (currentId, sendingId) => sendingId === currentId
)

export const selectCurrentUndoSendDelay = createBasicSelector(
  selectPreferences,
  preferences => preferences.undo_send_delay || 0
)

export const selectIsUndoActive = createBasicSelector(
  selectBase,
  undo => undo.isActive
)

export const selectCurrentUndoData = createBasicSelector(
  selectBase,
  ({ ticketId, draftId, changesetId, status, draft }) => ({
    ticketId,
    draftId,
    changesetId,
    status,
    draft,
  })
)
