import { selectContactsById } from 'ducks/crm/contacts/selectors/base'
import createCachedSelector from 're-reselect'
import { selectCurrentConversationById } from '.'

// Checks if customer email missing on non-twitter tickets
export const selectIsContactEmailBlankByConversationId = createCachedSelector(
  selectCurrentConversationById,
  selectContactsById,
  (ticket, contactsById) => {
    const contact = contactsById[ticket?.contact]
    return ticket && !ticket.isTwitterTicket && contact && !contact.email
  }
)((_state, conversationId) => conversationId || 'unknown')
