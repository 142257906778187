import { selectRequestByType } from 'ducks/requests/selectors'
import { createBasicSelector } from 'util/redux'
import { buildConversationEventGroupsRequestKey } from '../utils/request'

export const selectTicketEventsGroupRequestByConversationId = createBasicSelector(
  (state, conversationId) => {
    return selectRequestByType(
      state,
      buildConversationEventGroupsRequestKey(conversationId)
    )
  }
)
