import { CLOSED, DELETED, OPENED, SPAM, TRASH, UNREAD } from './constants'
import {
  conversationFragment,
  widgetConversationFragment,
  customFieldValueFragment,
  eventFragment,
  actorFragment,
  emailMessageFragment,
  widgetFragment,
  noteFragment,
  replyFragment,
  recipientFragment,
  forwardFragment,
  attachmentFragment,
  authorFragment,
  eventGroupFragment,
  allConversationFragments,
} from './fragments'

const updateConversationResponse = () => `
  changesetId
  conversation {
    ${allConversationFragments()}
  }
  diffs {
      query
      delta
  }
  eventGroup {
    ...eventGroupFragment
  }
  errors {
      path
      message
  }
`

const updateConversationFragments = () => `
${conversationFragment()}
${widgetConversationFragment()}
${customFieldValueFragment()}
${eventFragment()}
${actorFragment()}
${emailMessageFragment()}
${widgetFragment()}
${noteFragment()}
${replyFragment()}
${recipientFragment()}
${forwardFragment()}
${attachmentFragment()}
${authorFragment()}
${eventGroupFragment()}
`

export const updateConversationSubjectMutation = () => `
mutation TicketUpdateSubjectMutation(
    $conversationId: ID!,
    $subject: String!,
) {
    conversationSubject( input: { conversationId: $conversationId, subject: $subject}) {
      ${updateConversationResponse()}
    }
}
${updateConversationFragments()}
`

export const updateAddTagsToConversationMutation = () => `
mutation TicketAddTagsMutation(
    $conversationId: ID!,
    $tagIds: [ID!]!
) {
    conversationTag( input: { conversationId: $conversationId, tagIds: $tagIds }) {
      ${updateConversationResponse()}
    }
}
${updateConversationFragments()}
`

export const updateToggleNoteReactionMutation = () => `
mutation TicketToggleNoteReaction(
    $messageId: ID!,
    $reaction: String!
) {
    conversationToggleNoteReaction( input: { messageId: $messageId, reaction: $reaction }) {
      ${updateConversationResponse()}
    }
}
${updateConversationFragments()}
`

export const deleteNoteMutation = () => `
mutation TicketDeleteNote(
    $messageId: ID!
) {
    conversationDeleteNote( input: { messageId: $messageId }) {
      ${updateConversationResponse()}
    }
}
${updateConversationFragments()}
`

export const updateRemoveTagsToConversationMutation = () => `
mutation TicketRemoveTagstMutation(
    $conversationId: ID!,
    $tagIds: [ID!]!
) {
    conversationUntag( input: { conversationId: $conversationId, tagIds: $tagIds }) {
      ${updateConversationResponse()}
    }
}
${updateConversationFragments()}
`

export const updateStarConversationdMutation = () => `
mutation TicketStarMutation(
    $conversationId: ID!
) {
    conversationStar( input: { conversationId: $conversationId }) {
      ${updateConversationResponse()}
    }
}
${updateConversationFragments()}
`

export const updateUnstarConversationdMutation = () => `
mutation TicketUnstarMutation(
    $conversationId: ID!
) {
    conversationUnstar( input: { conversationId: $conversationId }) {
      ${updateConversationResponse()}
    }
}
${updateConversationFragments()}
`

const stateMutationConfig = {
  [UNREAD]: {
    mutationName: 'TicketStateUnreadMutation',
    queryName: 'conversationUnread',
    queryFilter: 'input: { conversationId: $conversationId}',
  },
  [OPENED]: {
    mutationName: 'TicketStateOpenMutation',
    queryName: 'conversationOpen',
    queryFilter: 'input: { conversationId: $conversationId}',
  },
  [CLOSED]: {
    mutationName: 'TicketStateCloseMutation',
    queryName: 'conversationClose',
    queryFilter: 'input: { conversationId: $conversationId}',
  },
  [SPAM]: {
    mutationName: 'TicketStateSpamMutation',
    queryName: 'conversationSpam',
    queryFilter: 'input: { conversationId: $conversationId}',
  },
  [TRASH]: {
    mutationName: 'TicketStateTrashMutation',
    queryName: 'conversationTrash',
    queryFilter: 'input: { conversationId: $conversationId}',
  },
  [DELETED]: {
    mutationName: 'TicketStateTrashMutation',
    queryName: 'conversationTrash',
    queryFilter: 'input: { conversationId: $conversationId}',
  },
}

export const updateConversationStateMutation = ({ state }) => {
  const config = stateMutationConfig[state]
  if (!config)
    throw new Error(
      `The state ${state} was not found in the stateMutationConfig`
    )
  const { mutationName, queryName, queryFilter } = config
  return `
mutation ${mutationName}(
    $conversationId: ID!
) {
    ${queryName}(${queryFilter}) {
      ${updateConversationResponse()}
    }
}
${updateConversationFragments()}
`
}

export const updateConversationStateSnoozeMutation = () => `
mutation TicketStateSnoozeMutation(
    $conversationId: ID!,
    $until: DateTime
) {
    conversationSnooze(input: { conversationId: $conversationId, until: $until }) {
      ${updateConversationResponse()}
    }
}
${updateConversationFragments()}
`

export const updateConversationStateDeleteMutation = () => `
mutation TicketDeleteMutation(
    $conversationIds: [ID!]!,
    $deleteMode: DeleteMode!,
) {
    conversationDelete(input: { conversationIds: $conversationIds, deleteMode: $deleteMode }) {
      deletedConversationIds
      diffs {
          query
          delta
      }
      errors {
          path
          message
      }
    }
}
`

export const updateConversationStateTrashMutation = () => `
mutation TicketDeleteMutation(
    $conversationIds: [ID!]!,
    $deleteMode: DeleteMode!,
) {
    conversationDelete(input: { conversationIds: $conversationIds, deleteMode: $deleteMode }) {
      deletedConversationIds
      diffs {
          query
          delta
      }
      errors {
          path
          message
      }
    }
}
`

export const updateFollowConversationdMutation = () => `
mutation TicketFollowMutation(
    $conversationId: ID!,
    $followerIds: [ID!]!
) {
    conversationFollow( input: { conversationId: $conversationId, followerIds: $followerIds }) {
      ${updateConversationResponse()}
    }
}
${updateConversationFragments()}
`

export const updateUnfollowConversationdMutation = () => `
mutation TicketUnfollowMutation(
    $conversationId: ID!,
    $followerIds: [ID!]!
) {
    conversationUnfollow( input: { conversationId: $conversationId, followerIds: $followerIds }) {
      ${updateConversationResponse()}
    }
}
${updateConversationFragments()}
`

export const updateConversationChannelMutation = () => `
mutation TicketChangeChannelMutation(
    $conversationId: ID!,
    $channelId: ID!,
    $skipNotifications: Boolean!,
) {
    conversationChangeChannel( input: { conversationId: $conversationId, channelId: $channelId, skipNotifications: $skipNotifications }) {
      ${updateConversationResponse()}
    }
}
${updateConversationFragments()}
`

export const updateMergeConversationMutation = () => `
mutation TicketMergeMutation(
    $sourceConversationId: ID!,
    $targetConversationId: ID!,
) {
    conversationMerge( input: { sourceId: $sourceConversationId, targetId: $targetConversationId }) {
      changesetId
      target {
        ${allConversationFragments()}
      }
      sourceDiffs {
          query
          delta
      }
      targetDiffs {
          query
          delta
      }
      errors {
          path
          message
      }
    }
}
${conversationFragment()}
${widgetConversationFragment()}
${customFieldValueFragment()}
`

export const updateConversationdAssigneeMutation = () => `
mutation TicketAssignMutation(
    $agentId: ID,
    $conversationId: ID!,
    $teamId: ID,
    $skipNotifications: Boolean
) {
    conversationAssign( input: { agentId: $agentId, conversationId: $conversationId, teamId: $teamId, skipNotifications: $skipNotifications } ) {
      ${updateConversationResponse()}
    }
}
${updateConversationFragments()}
`

export const updateConversationUnassignMutation = () => `
mutation TicketUnassignMutation(
    $conversationId: ID!,
    $agent: Boolean,
    $team: Boolean,
    $skipNotifications: Boolean
) {
    conversationUnassign( input: { agent: $agent, conversationId: $conversationId, team: $team, skipNotifications: $skipNotifications } ) {
      ${updateConversationResponse()}
    }
}
${updateConversationFragments()}
`

export const updateConversationReplyMutation = () => `
mutation TicketReplyMutation(
    $conversationId: ID!,
    $body: String!,
    $to: Email!,
    $cc: [Email],
    $bcc: [Email],
    $subject: String,
    $attachments: [EmailAttachmentInput],
    $cannedReplyId: ID,
    $draftId: String,
    $state: ConversationState,
    $snoozeUntil: DateTime,
    $tagIdsToAdd: [ID],
    $tagIdsToRemove: [ID],
    $removeAllTags: Boolean,
    $agentId: ID,
    $teamId: ID,
    $channelId: ID,
    $skipNotifications: Boolean
) {
    conversationReply(
      input: {
        conversationId: $conversationId,
        body: $body,
        to: $to,
        cc: $cc,
        bcc: $bcc,
        subject: $subject,
        attachments: $attachments,
        cannedReplyId: $cannedReplyId,
        draftId: $draftId,
        state: $state,
        snoozeUntil: $snoozeUntil,
        tagIdsToAdd: $tagIdsToAdd,
        tagIdsToRemove: $tagIdsToRemove,
        removeAllTags: $removeAllTags,
        agentId: $agentId,
        teamId: $teamId,
        channelId: $channelId,
        skipNotifications: $skipNotifications
      }
    ) {
      ${updateConversationResponse()}
    }
}
${updateConversationFragments()}
`

export const updateConversationAddNoteMutation = () => `
mutation TicketAddNoteMutation(
    $conversationId: ID!,
    $body: String!,
    $draftId: String,
    $state: ConversationState,
    $snoozeUntil: DateTime,
    $attachments: [EmailAttachmentInput],
    $skipNotifications: Boolean
) {
    conversationAddNote(
      input: {
        conversationId: $conversationId,
        body: $body,
        draftId: $draftId,
        state: $state,
        snoozeUntil: $snoozeUntil,
        attachments: $attachments,
        skipNotifications: $skipNotifications
      }
    ) {
      ${updateConversationResponse()}
    }
}
${updateConversationFragments()}
`

export const updateConversationEditNoteMutation = () => `
mutation TicketEditNoteMutation(
    $messageId: ID!,
    $body: String!,
    $attachments: [EmailAttachmentInput],
    $skipNotifications: Boolean
) {
    conversationEditNote(
      input: {
        messageId: $messageId,
        body: $body,
        attachments: $attachments,
        skipNotifications: $skipNotifications
      }
    ) {
      ${updateConversationResponse()}
    }
}
${updateConversationFragments()}
`

export const updateConversationNewMutation = () => `
mutation TicketNewMutation(
    $assigned: AssignmentInput,
    $channelId: ID!,
    $message: EmailMessageInput!,
    $state: ConversationState,
    $cannedReplyId: ID,
    $snoozeUntil: DateTime,
    $tagIds: [ID],
    $skipNotifications: Boolean
) {
    conversationCreateEmail(
      input: {
        assigned: $assigned,
        channelId: $channelId,
        message: $message,
        state: $state,
        cannedReplyId: $cannedReplyId,
        snoozeUntil: $snoozeUntil,
        tagIds: $tagIds,
        skipNotifications: $skipNotifications
      }
    ) {
      changesetId
      conversation {
        ...conversationFragment
      }
      errors {
          path
          message
      }
    }
}
${conversationFragment()}
${customFieldValueFragment()}
`

export const updateConversationLogMutation = () => `
mutation TicketLogMutation(
    $assigned: AssignmentInput,
    $channelId: ID!,
    $message: NoteMessageInput!,
    $state: ConversationState,
    $cannedReplyId: ID,
    $draftId: String,
    $snoozeUntil: DateTime,
    $skipNotifications: Boolean
) {
    conversationLog(
      input: {
        assigned: $assigned,
        channelId: $channelId,
        message: $message,
        state: $state,
        cannedReplyId: $cannedReplyId,
        draftId: $draftId,
        snoozeUntil: $snoozeUntil,
        skipNotifications: $skipNotifications
      }
    ) {
      changesetId
      conversation {
        ...conversationFragment
      }
      errors {
          path
          message
      }
    }
}
${conversationFragment()}
${customFieldValueFragment()}
`

export const updateConversationForwardMutation = () => `
mutation TicketForwardMutation(
    $conversationId: ID!,
    $body: String!,
    $to: Email!,
    $cc: [Email],
    $bcc: [Email],
    $subject: String,
    $attachments: [EmailAttachmentInput],
    $cannedReplyId: ID,
    $draftId: String,
    $state: ConversationState,
    $snoozeUntil: DateTime
    $agentId: ID,
    $teamId: ID,
    $channelId: ID,
    $skipNotifications: Boolean
) {
    conversationForward(
      input: {
        conversationId: $conversationId,
        body: $body,
        to: $to,
        cc: $cc,
        bcc: $bcc,
        subject: $subject,
        attachments: $attachments,
        cannedReplyId: $cannedReplyId,
        draftId: $draftId,
        state: $state,
        snoozeUntil: $snoozeUntil
        agentId: $agentId,
        teamId: $teamId,
        channelId: $channelId,
        skipNotifications: $skipNotifications
      }
    ) {
      ${updateConversationResponse()}
    }
}
${updateConversationFragments()}
`
