import { doDeleteDraftLocally } from 'ducks/drafts2/operations'
import { saveRequests } from 'ducks/drafts2/operations/saveRequests'
import { doUpdateEditorVisibility } from 'ducks/editor'
import { isPromise } from 'util/functions'
import { FAILED_SEND } from 'ducks/drafts2/constants'
import { selectDraftById } from 'ducks/drafts2/selectors'
import {
  FETCH_CONVERSATION,
  FETCH_CONVERSATION_EVENT_GROUPS,
  FETCH_CONVERSATION_EVENTS_BY_GROUP_ID,
} from '../actionTypes'
import { doAutoRedirect } from '../actions/doAutoRedirect'

export const buildConversationRequestKey = conversationId =>
  `${FETCH_CONVERSATION}_${conversationId}`

export const buildConversationEventGroupsRequestKey = conversationId =>
  `${FETCH_CONVERSATION_EVENT_GROUPS}_${conversationId}`

export const buildConversationEventsByGroupIdRequestKey = eventGroupId =>
  `${FETCH_CONVERSATION_EVENTS_BY_GROUP_ID}_${eventGroupId}`

const defaultExtractTicketId = ticketId => () => ticketId
export const withAutoRedirect = (
  ticketId,
  action,
  options = {}
) => async dispatch => {
  const {
    moduleOptions: {
      autoRedirect: {
        enabled: attemptRedirect = true,
        redirectFirst,
        extractTicketId: inputExtractTicketId,
        // Ignore the default rule and force a redirection
        forced = false,
      } = {},
    } = {},
  } = options

  const extractTicketId =
    inputExtractTicketId || defaultExtractTicketId(ticketId)

  if (attemptRedirect) {
    if (redirectFirst) {
      await dispatch(doAutoRedirect(ticketId, { forced }))
      return dispatch(action)
    }
    const response = await dispatch(action)
    const extractedTicketId = extractTicketId(response)
    if (extractedTicketId)
      dispatch(doAutoRedirect(extractedTicketId, { forced }))
    return response
  }
  return dispatch(action)
}

export const onUpdateAttachEventGroupIdToEvents = mutationName => {
  return data => {
    if (data[mutationName].eventGroup) {
      const eventGroup = data[mutationName].eventGroup
      eventGroup.isLastMessage = true
      eventGroup.events.edges.forEach(e => {
        // eslint-disable-next-line no-param-reassign
        e.node.eventGroupId = eventGroup.id
      })
    }
    return data
  }
}

export const withBeforeSend = (ticketId, draftId, action) => async (
  dispatch,
  getState
) => {
  const draft = selectDraftById(getState(), draftId)

  dispatch(doDeleteDraftLocally(ticketId, draftId))
  // TODO: We can rather have the editor hide as part of the START operation
  // on the send
  dispatch(doUpdateEditorVisibility(false))
  const draftSavePromise = saveRequests[draftId]
  if (isPromise(draftSavePromise)) {
    await draftSavePromise
    delete saveRequests[draftId]
  }
  try {
    const response = await dispatch(action)
    return response
  } catch (e) {
    dispatch({
      type: FAILED_SEND,
      payload: { draftId, draft },
    })
    dispatch(doUpdateEditorVisibility(true))
    throw e
  }
}
