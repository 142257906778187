import React, { PureComponent } from 'react'
import { withTheme } from '@emotion/react'
import { SUI } from 'shared/ui'

import GA from 'util/googleAnalytics'
import { propFunc, isFunction } from 'util/functions'
import { inBody } from 'util/hoc'

import TicketState from 'components/TicketState'
import StarIcon from 'components/StarIcon'
import { CHANGE_TYPE_FORWARDED_MESSAGE } from 'ducks/tickets/constants'

import ReadStateButton from '../ReadStateButton'
import TicketStateButton from '../TicketStateButton'

import TicketPreviewComment from './Comment'
import TicketPreviewPlaceholder from './Placeholder'

import styles from './styles.less'

class TicketPreviewView extends PureComponent {
  static trackGA = label => {
    return GA.track('Inbox - Ticket Preview Bottom Bar', 'Clicked', label)
  }

  static stopPropagation = e => {
    e.stopPropagation()
  }

  static formatRecipients = recipientList => {
    return recipientList?.map(d => d.email).join(', ')
  }

  componentDidMount = () => {
    this.fetchTicketComments()
  }

  componentDidUpdate = prevProps => {
    const { isModalOpen, isLoading } = this.props
    this.fetchTicketComments()

    if (!prevProps.isModalOpen && isModalOpen && !isLoading)
      this.scrollLastItemIntoView()
  }

  getModalSize = () => {
    const { width = 560, height = 400 } = this.props
    return {
      width,
      height,
    }
  }

  getStyle = () => {
    return {
      ...this.calculateOnScreenPosition(),
      ...this.getModalSize(),
    }
  }

  calculateOnScreenPosition = () => {
    const {
      modalOffset: { left, top, eventOffsetRight, eventOffsetLeft },
    } = this.props
    const { width, height } = this.getModalSize()
    let onScreenLeft = left
    let onScreenTop = top - height / 2 // Middle align modal with mouse
    const viewportWidth = window.innerWidth
    const viewportHeight = window.innerHeight

    if (eventOffsetRight) {
      onScreenLeft -= width + eventOffsetRight
    } else if (eventOffsetLeft) {
      onScreenLeft += eventOffsetLeft
    }

    if (onScreenLeft + width > viewportWidth)
      onScreenLeft = viewportWidth - width
    if (onScreenTop + height > viewportHeight)
      onScreenTop = viewportHeight - height

    if (onScreenLeft < 0) onScreenLeft = 0
    if (onScreenTop < 0) onScreenTop = 0
    return {
      left: onScreenLeft,
      top: onScreenTop,
    }
  }

  isLoadRequested = false

  fetchTicketComments = () => {
    const { fetchTicketComments, ticketId, isModalOpen } = this.props
    if (isModalOpen && !this.isLoadRequested) {
      this.isLoadRequested = true
      if (isFunction(fetchTicketComments)) {
        fetchTicketComments(ticketId).then(this.scrollLastItemIntoView)
      }
    }
  }

  isAiReplyRequest = false

  commentRefs = []

  scrollLastItemIntoView = () => {
    const lastItem = this.commentRefs[this.commentRefs.length - 1]
    if (lastItem && lastItem.current) {
      lastItem.current.scrollIntoView()
    }
  }

  render() {
    const {
      ticketId,
      ticket,
      flags: { isDeleted, isSpam, isSnoozed, isStarred } = {},
      comments,
      isModalOpen,
      isLoading,
      showState = false,
      showMark = false,
      toggleStarred,
      theme,
      delayedHide,
      cancelHide,
    } = this.props

    const { subject: title, counts: { messages: messageCount } = {} } =
      ticket || {}

    if (!isModalOpen) return null

    return (
      <SUI id={`ticket-preview-${ticketId}`}>
        <div
          className={styles.modal}
          style={this.getStyle()}
          onMouseLeave={delayedHide}
          onMouseMove={cancelHide}
          onClick={TicketPreviewView.stopPropagation}
        >
          <div className={styles.header}>
            <div className={styles.title}>
              <StarIcon
                isStarred={isStarred}
                onStarClick={propFunc(toggleStarred, ticketId)}
                size={20}
              />{' '}
              {title}
            </div>
            <div className={styles.ticketid}>#{ticketId}</div>
          </div>
          <div className={styles.body}>
            {isLoading && <TicketPreviewPlaceholder count={messageCount} />}
            {!isLoading &&
              comments.map(
                ({
                  id,
                  author: {
                    __typename: authorType,
                    name: authorName = null,
                    email: authorEmail = null,
                  } = {},
                  bodyPlainText: body,
                  createdAt,
                  isNote,
                  cc,
                  bcc,
                  __typename: messageType,
                }) => {
                  const isForward =
                    messageType === CHANGE_TYPE_FORWARDED_MESSAGE
                  const isAgentResponse = authorType === 'Agent'
                  const commentRef = React.createRef()
                  this.commentRefs.push(commentRef)
                  const key = `ticket-${ticketId}-comment-${id}`
                  return (
                    <TicketPreviewComment
                      key={key}
                      ref={commentRef}
                      ticketId={ticketId}
                      authorName={authorName || authorEmail}
                      authorEmail={authorEmail}
                      body={body}
                      createdAt={createdAt}
                      isNote={isNote}
                      isForward={isForward}
                      isAgentResponse={isAgentResponse}
                      cc={TicketPreviewView.formatRecipients(cc)}
                      bcc={TicketPreviewView.formatRecipients(bcc)}
                    />
                  )
                }
              )}
          </div>
          <div className={styles.footer}>
            <TicketState
              className={styles.spacing}
              isDeleted={isDeleted}
              isSpam={isSpam}
              isSnoozed={isSnoozed}
              theme={theme}
            />
            {showState && (
              <TicketStateButton
                ticketId={ticketId}
                className={styles.moveRight}
              />
            )}
            {showMark && <ReadStateButton ticketId={ticketId} />}
          </div>
        </div>
      </SUI>
    )
  }
}

export default inBody(withTheme(TicketPreviewView))
