import { doFetchConversations } from 'ducks/searches/operations/doFetchConversations'
import { selectChannelTypeFromUrl } from 'ducks/channels/selectors'
import { selectSearchNextPageCursorByQueryId } from 'ducks/searches/selectors'
import { selectCurrentQueryId } from 'ducks/searches/selectors/selectCurrentQueryId'
import { queryIdToQuery } from 'ducks/searches/utils/query'

export const doFetchTickets = (opts = {}) => (dispatch, getState) => {
  const { isReload, loadFirst, queryId: inputQueryId } = opts
  const state = getState()
  const queryId = inputQueryId || selectCurrentQueryId(state)
  const pageChannelType = selectChannelTypeFromUrl(state)
  const nextPageCursor = loadFirst
    ? null
    : selectSearchNextPageCursorByQueryId(state, queryId)
  const { type: channelType = pageChannelType } = queryIdToQuery(queryId) || {}

  return dispatch(
    doFetchConversations({
      channelType,
      queryId,
      cursor: nextPageCursor,
      size: 20,
      options: {
        skipStarted: !!isReload,
      },
    })
  )
}
