import { createBasicSelector } from 'util/redux'

import {
  NEWEST,
  NEWEST_BY_CLOSED,
  NEWEST_BY_COLLABORATOR,
  NEWEST_BY_DELETED,
  NEWEST_BY_SPAM,
  OLDEST,
  OLDEST_BY_COLLABORATOR,
} from 'constants/defaults'
import { selectFolderSortOrderContextIdByQueryId } from 'ducks/folders/selectors/folders/selectFolderSortOrderContextIdByQueryId'
import { flatten, uniq } from 'util/arrays'
import { SORT_CONTEXT_KEY_DELIMITER } from 'util/search/sorting'
import { selectAccountPreferenceSortByCollaboratorCommentEnabled } from 'selectors/app/selectAccountPreferences'
import { QUERY_PRIMARY_ALIAS } from '../utils/query'
import { selectQueryByQueryId } from '.'

const isState = (query, state) => {
  return query?.is?.includes(state) || query?.state === state
}

export const selectSortContextIdByQueryId = createBasicSelector(
  (state, queryId) => {
    const sortByCollaboratorCommentAtEnabled = selectAccountPreferenceSortByCollaboratorCommentEnabled(
      state
    )
    const query = selectQueryByQueryId(state, queryId)

    const contextIdParts = []
    const hasDeletedQuery = isState(query, 'deleted') || isState(query, 'trash')
    const hasClosedQuery = isState(query, 'closed')
    const hasSpamQuery = isState(query, 'spam')

    if (hasDeletedQuery) {
      contextIdParts.push(NEWEST_BY_DELETED)
    }

    if (hasClosedQuery) {
      contextIdParts.push(NEWEST_BY_CLOSED)
    }

    if (hasSpamQuery) {
      contextIdParts.push(NEWEST_BY_SPAM)
    }

    if (query[QUERY_PRIMARY_ALIAS.folderId]) {
      contextIdParts.push(
        selectFolderSortOrderContextIdByQueryId(state, queryId)
      )
    }

    if (sortByCollaboratorCommentAtEnabled) {
      contextIdParts.push(NEWEST_BY_COLLABORATOR)
      contextIdParts.push(OLDEST_BY_COLLABORATOR)
    } else {
      contextIdParts.push(NEWEST)
      contextIdParts.push(OLDEST)
    }

    // It is possible for you to specify a search which has a folder and a is:state selector.
    // In this situation the same sort option could be added to the context parts twice because
    // the folder could for example be a deleted ticket folder, and the customer has specified
    // is:deleted. In this case we just want to make sure we stripe out those duplicates
    return uniq(
      flatten(
        contextIdParts.map(part => part.split(SORT_CONTEXT_KEY_DELIMITER))
      )
    ).join(SORT_CONTEXT_KEY_DELIMITER)
  }
)
