import { mergePlain } from 'util/merge'
import { doGraphqlRequest } from 'ducks/requests/operations'
import { buildId } from 'util/globalId'
import { withUndoSend } from 'ducks/undoSend/request'
import { CONVERSATION_REPLY } from '../actionTypes'
import { updateConversationReplyMutation } from '../mutations'
import { CONVERSATION_SAVE_UNLOAD_MESSAGE, SNOOZED } from '../constants'
import { conversationReplyGraphQlResponseSchema } from '../schema'
import { selectCurrentConversationById } from '../selectors'
import {
  onUpdateAttachEventGroupIdToEvents,
  withAutoRedirect,
  withBeforeSend,
} from '../utils/request'
import { buildConversationOptimistRequestOptions } from '../utils/optimistic'
import { selectWillAutoRedirectForTicketId } from '../selectors/selectWillAutoRedirectForTicketId'

export const doReply = (
  ticketId,
  body,
  to,
  cc,
  bcc,
  subject,
  attachments,
  cannedReplyId,
  draftId,
  inputState,
  snoozeUntil,
  tagIdsToAdd,
  tagIdsToRemove,
  removeAllTags,
  inputAgentId,
  inputTeamId,
  inputChannelId,
  options = {}
) => async (dispatch, getState) => {
  const conversationId = buildId('Conversation', ticketId)
  const agentId = buildId('Agent', inputAgentId)
  const teamId = buildId('Team', inputTeamId)
  const channelId = buildId('Channel', inputChannelId)

  const store = getState()
  const ticket = selectCurrentConversationById(store, ticketId)
  const state = snoozeUntil ? SNOOZED : inputState
  if (!ticket || !body || !to || !to[0]) return null

  const {
    optimist,
    additionalActions,
  } = await buildConversationOptimistRequestOptions(
    getState,
    ticketId,
    {
      conversationId: ticketId,
      subject,
      state,
      snoozeUntil,
      tagIdsToAdd,
      tagIdsToRemove,
      removeAllTags,
      agentId,
      teamId,
      channelId,
      reply: {
        body,
        attachments,
        to,
        cc,
        bcc,
      },
    },
    options
  )

  const backHref = window.location.href
  const willAutoRedirect = selectWillAutoRedirectForTicketId(
    getState(),
    ticketId,
    !!options?.moduleOptions?.autoRedirect?.forced
  )

  return dispatch(
    withUndoSend(
      draftId,
      withBeforeSend(
        ticketId,
        draftId,
        withAutoRedirect(
          ticketId,
          doGraphqlRequest(
            CONVERSATION_REPLY,
            updateConversationReplyMutation(),
            {
              conversationId,
              body,
              // In future we should remove this once we support having multiple to
              // addresses
              to: to[0],
              cc,
              bcc,
              subject,
              attachments,
              cannedReplyId,
              draftId,
              state,
              snoozeUntil,
              tagIdsToAdd,
              tagIdsToRemove,
              removeAllTags,
              agentId,
              teamId,
              channelId,
              skipNotifications: !!options.skipNotifications,
            },
            mergePlain(
              {
                app: true,
                throwOnError: true,
                concurrency: {
                  key: ticketId,
                  message: CONVERSATION_SAVE_UNLOAD_MESSAGE,
                },
                normalizationSchema: conversationReplyGraphQlResponseSchema,
                transformResponse: onUpdateAttachEventGroupIdToEvents(
                  'conversationReply'
                ),
                optimist,
                moduleOptions: {
                  snackbar: {
                    enabled: true,
                    started: {
                      enabled: true,
                      message: 'Sending your reply...',
                    },
                    success: {
                      enabled: true,
                      message: `Your email has been sent.`,
                      link: willAutoRedirect && {
                        href: backHref,
                        text: `Go back to ${app.t('Ticket')}`,
                      },
                    },
                    failed: {
                      enabled: true,
                      message: `Oops, we couldn't send your reply. Please try again in a moment.`,
                    },
                  },
                  entities: {
                    additionalActions,
                  },
                },
                meta: {
                  mergeEntities: true,
                },
              },
              options
            )
          ),
          options
        )
      )
    )
  )
}
