import { createSelector } from 'reselect'
import { selectCurrentTicketAssignedAgent } from './selectCurrentTicketAssignedAgent'
import { selectCurrentTicketAssignedGroup } from './selectCurrentTicketAssignedGroup'

export const selectCurrentTicketAssignment = createSelector(
  selectCurrentTicketAssignedAgent,
  selectCurrentTicketAssignedGroup,
  (agent, group) => {
    return group || agent || null
  }
)
