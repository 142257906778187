import createCachedSelector from 're-reselect'
import { selectIsOnSearchPage } from 'selectors/location'
import { queryIdToQuery, toFilterQueryId } from '../utils/query'

export const selectSearchQueryStringByQueryId = createCachedSelector(
  (_state, queryId) => toFilterQueryId(queryId),
  selectIsOnSearchPage,
  (queryId, isSearchPage) => {
    if (!isSearchPage || !queryId) return null
    return queryIdToQuery(queryId).search
  }
)((_state, queryId) => queryId || 'unknown')
