// Return preset title for a query, or if there isn't one the query string.
// !IMPORTANT If you add a titled search you must ensure it's query ID is added

import { capitalize } from 'util/strings'
import { queryIdToQuery, queryStringToQueryId } from './query'

// to the server side's TicketFilterDetector so that diff's include it.
const searchQueryTitles = {
  'is:open': 'Open',
  'is:closed': 'Closed',
  'is:snoozed': 'Snoozed',
  'is:unread': 'Unread',
  'is:deleted': 'Deleted',
  'is:spam': 'Spam',
  // !IMPORTANT see comment at top
  'assignee:me': 'All of Mine',
  'my:open': 'Mine',
  'my:closed': 'My Closed',
  'my:snoozed': 'My Snoozed',
  'my:unread': 'My Unread',
  'my:deleted': 'My Deleted',
  // !IMPORTANT see comment at top
  'my:drafts': 'All of My Drafts',
  'my:drafts is:open': 'My Drafts',
  'my:drafts is:closed': 'My Closed Drafts',
  'my:drafts is:snoozed': 'My Snoozed Drafts',
  'my:drafts is:deleted': 'Deleted with My Drafts',
  'my:drafts is:spam': 'Spam with My Drafts',
  // !IMPORTANT see comment at top
  'is:unassigned': 'All Unassigned',
  'is:unassigned is:open': 'Unassigned',
  'is:unassigned is:closed': 'Unassigned Closed',
  'is:unassigned is:snoozed': 'Unassigned Snoozed',
  'is:unassigned is:unread': 'Unassigned Unread',
  'is:unassigned is:deleted': 'Unassigned Deleted',
  'is:unassigned is:spam': 'Unassigned Spam',
  // !IMPORTANT see comment at top
  'is:assigned': 'All Assigned',
  'is:assigned is:open': 'Assigned',
  'is:assigned is:closed': 'Assigned Closed',
  'is:assigned is:snoozed': 'Assigned Snoozed',
  'is:assigned is:unread': 'Assigned Unread',
  'is:assigned is:deleted': 'Assigned Deleted',
  'is:assigned is:spam': 'Assigned Spam',
  // !IMPORTANT see comment at top
  'is:starred': 'All Starred',
  'is:starred is:open': 'Starred',
  'is:starred is:closed': 'Starred Closed',
  'is:starred is:snoozed': 'Starred Snoozed',
  'is:starred is:unread': 'Starred Unread',
  'is:starred is:deleted': 'Starred Deleted',
  'is:starred is:spam': 'Starred Spam',
}

export function reverseSearchIdValueMap(idValueMap) {
  return Object.keys(idValueMap).reduce((reversingIdValueMap, key) => {
    // eslint-disable-next-line no-param-reassign
    reversingIdValueMap[key] = Object.keys(idValueMap[key]).reduce(
      (reversedIdValueSet, id) => {
        const value = idValueMap[key][id]
        // eslint-disable-next-line no-param-reassign
        reversedIdValueSet[value] = id
        return reversedIdValueSet
      },
      {}
    )
    return reversingIdValueMap
  }, {})
}

export function constructSearchQueryTitle(queryId, idValueMap = {}) {
  const reversedIdValueMap = reverseSearchIdValueMap(idValueMap)
  const query = queryIdToQuery(queryId)
  const modifiedQuery = { ...query }

  // Query titles are the same regardless of mailbox
  delete modifiedQuery.channel
  const queryIdLessMailbox = queryStringToQueryId(modifiedQuery)
  if (searchQueryTitles[queryIdLessMailbox])
    return searchQueryTitles[queryIdLessMailbox]

  const state = modifiedQuery.state
  const deleted = modifiedQuery.deleted

  delete modifiedQuery.state
  delete modifiedQuery.deleted
  delete modifiedQuery.draftType
  delete modifiedQuery.type
  delete modifiedQuery.entityType
  delete modifiedQuery.orderBy
  delete modifiedQuery.cursor
  if (Object.keys(modifiedQuery).length === 1) {
    const [key, value] = Object.entries(modifiedQuery)[0]
    const valueMapped =
      reversedIdValueMap[key] && reversedIdValueMap[key][value]
    const stateTitle =
      (deleted === 'all' && ' Deleted') ||
      (state && state !== 'opened' ? ` ${capitalize(state)}` : '')
    if (
      value === 'unassigned' &&
      (key === 'assignee' || key === 'assigned_group')
    ) {
      if (state === 'closed') {
        return `Closed Unassigned`
      } else if (state === 'snoozed') {
        return `Snoozed Unassigned`
      } else if (state === undefined) {
        return `All Unassigned`
      }
      return `Unassigned`
    }
    if (key === 'assignee') {
      const all = !state && !deleted ? 'All of ' : ''
      if (state === 'spam' && (valueMapped || value) === 'me') return 'My Spam'
      return `${all}${capitalize(valueMapped || value)}'s${stateTitle}`
    }
    if (key === 'folder') {
      // Show nothing if we only have an ID + folder mappings arent yet loaded
      if (!isNaN(value) && !valueMapped) return ''
      return `${valueMapped || value}`
    }
    if (key === 'team') {
      const all = !state && !deleted ? 'All in ' : ''
      return `${all}${valueMapped || value}${stateTitle}`
    }
    if (key === 'tag') {
      // Show nothing if we only have an ID + label mappings arent yet loaded
      if (!isNaN(value) && !valueMapped) return ''

      const all = !state && !deleted ? 'All ' : ''
      return `${all}${valueMapped || value}${stateTitle}`
    }
    if (key === 'mentions') {
      if (valueMapped === 'me') {
        switch (state) {
          case 'closed':
            return 'My Closed Mentions'
          case 'snoozed':
            return 'My Snoozed Mentions'
          case undefined:
            return 'All My Mentions'
          default:
            return 'My Mentions'
        }
      }

      if (valueMapped) {
        switch (state) {
          case 'closed':
            return `Closed @${valueMapped}`
          case 'snoozed':
            return `Snoozed @${valueMapped}`
          case undefined:
            return `All @${valueMapped}`
          default:
            return `@${valueMapped}`
        }
      }

      return 'Mentions'
    }
  }
  return null
}
