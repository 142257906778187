import createCachedSelector from 're-reselect'
import { selectConversationEventGroups } from '.'

export const selectTicketEventGroupsByConversationId = createCachedSelector(
  state => selectConversationEventGroups(state, false),
  (_state, conversationId) => conversationId,
  (conversationEventGroups, conversationId) => {
    return conversationEventGroups.filter(
      ceg => ceg.conversationId === conversationId
    )
  }
)((_state, conversationId) => conversationId || 'unknown')
