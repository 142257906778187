import { doGraphqlRequest } from 'ducks/requests/operations'
import editor from 'shared/editor/utils'
import { isBlank } from 'util/strings'

import { selectArticles, selectKnowledgeBases } from '../../selectors/kbSearch'
import { FETCH_KB_ARTICLES, CLEAR_KB_ARTICLES } from '../../types'

export const doClearSearchResults = () => dispatch => {
  dispatch({
    type: CLEAR_KB_ARTICLES,
  })
  return Promise.resolve()
}

export const doInsertKbLink = (articleID, knowledgeBaseId) => (
  dispatch,
  getState
) => {
  if (editor.getEditor()) {
    const state = getState()
    const articles = selectArticles(state)
    const knowledgeBases = selectKnowledgeBases(state)
    const article = articles.find(a => a.id === articleID)
    const knowledgeBase = knowledgeBases.find(kb => kb.id === knowledgeBaseId)
    if (knowledgeBase && article) {
      const isNote = !editor.getEditor().plugins.link
      const isActive =
        knowledgeBase.enabled && ['published', 'wip'].includes(article.state)
      const url = `${knowledgeBase.siteUrl}/${article.slug}`
      const title = article.title
      const html = `${isNote ? url : `<a href="${url}">${title}</a>`}&nbsp;`

      if (isActive || isNote) {
        editor.insertContent(html)
        return doClearSearchResults()(dispatch, getState)
      }
    }
  }
  return Promise.reject()
}

const basicArticleFields = `
  id
  title
  description
  state
  knowledgeBaseId
  slug
`

export const doSearchForArticles = (
  { keyword, knowledgeBaseId },
  options
) => dispatch => {
  if (isBlank(keyword)) return doClearSearchResults()(dispatch)
  const query = `
      query ArticlesQuery($knowledgeBaseId: ID, $keyword: String) {
        articles(
          knowledgeBaseId: $knowledgeBaseId
          state: ["wip", "published", "draft"]
          keyword: $keyword
          unpaginated: true
          includeFields: ["id", "title", "description", "state", "knowledge_base_id", "slug"]
          source: "es"
        ){
          data {
            ${basicArticleFields}
          }
        }
      }
    `

  return dispatch(
    doGraphqlRequest(
      FETCH_KB_ARTICLES,
      query,
      { keyword, knowledgeBaseId },
      options
    )
  )
}
