import createCachedSelector from 're-reselect'
import { selectPrefersClassicView } from 'ducks/currentUser/selectors/preferences/selectPrefersClassicView'
import { selectPrefersOpenFolderOnTicketSave } from 'ducks/currentUser/selectors/preferences/selectPrefersOpenFolderOnTicketSave'
import { selectSearchNextEntityIdByForCurrentQueryIdAndTicketId } from 'ducks/searches/selectors'
import { selectAutoAdvanceToNextTicketInList } from 'ducks/currentUser/selectors/preferences/selectAutoAdvanceToNextTicketInList'
import { selectCurrentTicketId } from '../selectors/selectCurrentTicketId'

// Ensure the logic in here matches the logic in doAutoRedirect
export const selectWillAutoRedirectForTicketId = createCachedSelector(
  selectPrefersClassicView,
  selectPrefersOpenFolderOnTicketSave,
  selectSearchNextEntityIdByForCurrentQueryIdAndTicketId,
  selectAutoAdvanceToNextTicketInList,
  selectCurrentTicketId,
  (_state, ticketId) => ticketId,
  (_state, _ticketId, forced) => forced,
  (
    classicView,
    shouldOpenFolder,
    nextTicketId,
    shouldAdvance,
    currentTicketId,
    ticketId,
    forced
  ) => {
    if (ticketId && currentTicketId !== ticketId && forced) {
      return true
    } else if (shouldAdvance || forced) {
      if (nextTicketId) {
        return true
      }
    } else if ((classicView && shouldOpenFolder) || forced) {
      return true
    }
    return false
  }
)((_state, ticketId, forced) => `${ticketId || 'unknown'}-${!!forced}`)
