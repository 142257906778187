import createCachedSelector from 're-reselect'
import { selectConversationEventGroups } from '.'

export const selectConversationEventGroupsByConversationId = createCachedSelector(
  (state, conversationId) =>
    selectConversationEventGroups(state, conversationId, true),
  (_state, conversationId) => conversationId,
  (eventGroups, conversationId) => {
    return eventGroups.filter(g => g.conversationId === conversationId)
  }
)((_state, conversationId) => conversationId || 'unknown')
