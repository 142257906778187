import {
  conversationFragment,
  widgetConversationFragment,
  customFieldValueFragment,
  allConversationFragments,
} from 'ducks/tickets/fragments'

export const ticketConversationsQuery = () => `
query TicketConversationsQuery($filter: ConversationFilter, $orderBy: ConversationOrder, $cursor: String, $size: Int){
  conversations(filter: $filter, orderBy: $orderBy, after: $cursor, first: $size) {
    nodes {
      ${allConversationFragments()}
    }
    totalCount
    totalPageCount
    pageInfo {
      hasNextPage
      hasPreviousPage
      endCursor
      startCursor
    }
  }
}
${conversationFragment()}
${widgetConversationFragment()}
${customFieldValueFragment()}
`

export const roomConversationsQuery = () => `
  query roomConversationsQuery($filter: RoomFilter, $cursor: String, $size: Int, $orderBy: RoomOrder ) {
    rooms(filter: $filter, after: $cursor, first: $size, orderBy: $orderBy) {
      totalCount
      totalPageCount
      nodes {
        id
        number
        contact {
          id
          name
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        endCursor
        startCursor
      }
    }
  }
`

const pinnedSearchPart = `
pinnedSearches(filter: $pinnedSearchFilter) {
  nodes {
    id
    queryId
    conversationCount
  }
}
`

export const ticketConversationCountForChannelQuery = ({
  canUsePinnedSearches,
}) => `
query TicketConversationCountForChannelQuery($channelId: ID!${
  canUsePinnedSearches ? ', $pinnedSearchFilter: PinnedSearchFilter!' : ''
}) {
  node(id: $channelId) {
    ... on Channel {
      folders {
        nodes {
          id
          conversationCount
        }
      }
      tags {
        nodes {
          id
          conversationCount(filter: { states: [OPENED] })
        }
      }
      permittedAgents {
        nodes {
          id
          conversationCount
        }
      }
      ${canUsePinnedSearches ? pinnedSearchPart : ''}
    }
  }
}
`

export const ticketConversationCountQuery = ({ canUsePinnedSearches }) => `
query TicketConversationCountQuery${
  canUsePinnedSearches ? '($pinnedSearchFilter: PinnedSearchFilter!)' : ''
} {
  channels {
    nodes {
      id
      conversationCount
    }
  }
  folders {
    nodes {
      id
      conversationCount
    }
  }
  tags {
    nodes {
      id
      conversationCount(filter: { states: [OPENED] })
    }
  }
  agents {
    nodes {
      id
      conversationCount
    }
  }
  ${canUsePinnedSearches ? pinnedSearchPart : ''}
}
`

export const roomConversationCountForChannelQuery = () => `
query roomConversationCountForChannelQuery($channelId: ID, $channelType: RoomChannelTypeEnum!) {
  roomsCounts(channelId: $channelId, channelType: $channelType) {
    lastUpdatedAt
    data {
      id
      conversationCount
      unreadCount
    }
  }
}
`
