export const emailMessageFragment = () => `
fragment emailMessageFragment on EmailMessage {
  __typename
  id
  summary {
    bodyPlainText
    __typename
  }
  parts {
    edges {
      node {
        content
        type
      }
    }
  }
  body
  channel {
    id
    name
    __typename
  }
  createdAt
  attachments {
    nodes {
      ...attachmentFragment
      __typename
    }
    __typename
  }
  isNote
  to {
    ...recipientFragment
    __typename
  }
  cc {
    ...recipientFragment
    __typename
  }
  bcc {
    ...recipientFragment
    __typename
  }
  author {
    ...authorFragment
    __typename
  }
}`

const urlQuery = `
  url {
    domain
    hash
    path
    protocol
    query
    raw
  }
`

export const customFieldValueFragment = () => `
fragment customFieldValueFragment on CustomFieldValue {
  __typename
  id
  customField {
    id
    key
    isArray
  }
  value {
    __typename
    ... on Text {
      content
    }
    ... on Address {
      city
      country
      postalCode
      state
      street
      street2
    }
    ... on IpAddress {
      address
      city
      subdivisions
      country
    }
    ... on Link {
      text
      link
    }
    ... on Money {
      amount
      currencyCode
    }
    ... on Session {
      totalTimeOnPage
      totalPageViews
      firstPageView {
        referrer {
          source
          medium
        }
        ${urlQuery}
        occurredAt
      }
      lastPageView {
        ${urlQuery}
        occurredAt
      }
      penultimatePageView {
        ${urlQuery}
        occurredAt
      }
    }
    ... on UserAgent {
      browser {
        family
        version
      }
      device {
        family
      }
      platform {
        family
        version
      }
    }
    ... on File {
      url
    }
  }
}`

export const widgetFragment = () => `
fragment widgetFragment on WidgetMessage {
  __typename
  id
  summary {
    bodyPlainText
    __typename
  }
  body
  channel {
    id
    name
    __typename
  }
  createdAt
  attachments {
    nodes {
      ...attachmentFragment
      __typename
    }
    __typename
  }
}`

export const noteFragment = () => `
fragment noteFragment on Note {
  __typename
  id
  summary {
    bodyPlainText
    body
    createdAt
    __typename
  }
  body
  createdAt
  attachments {
    nodes {
      ...attachmentFragment
      __typename
    }
    __typename
  }
}
`

export const replyFragment = () => `
fragment replyFragment on Reply {
  __typename
  id
  summary {
    bodyPlainText
    body
    createdAt
    __typename
  }
  body
  channel {
    id
    name
    __typename
  }
  createdAt
  attachments {
    nodes {
      ...attachmentFragment
      __typename
    }
    __typename
  }
  to {
    ...recipientFragment
    __typename
  }
  cc {
    ...recipientFragment
    __typename
  }
  bcc {
    ...recipientFragment
    __typename
  }
}
`

export const authorFragment = () => `
fragment authorFragment on Author {
  ... on Contact {
    id
    name
    email
    role
    avatarUrl
    __typename
  }
  ... on Agent {
    id
    name
    email
    role
    avatarUrl
    __typename
  }
}
`

export const recipientFragment = () => `
fragment recipientFragment on RecipientConnection {
  nodes {
    ... on Contact {
      id
      name
      email
      __typename
    }
    ... on Agent {
      id
      name
      email
      __typename
    }
    __typename
  }
  __typename
}
`

export const forwardFragment = () => `
fragment forwardFragment on ForwardedMessage {
  __typename
  id
  summary {
    bodyPlainText
    body
    createdAt
    __typename
  }
  body
  channel {
    id
    name
    __typename
  }
  createdAt
  attachments {
    nodes {
      ...attachmentFragment
      __typename
    }
    __typename
  }
  to {
    ...recipientFragment
    __typename
  }
  cc {
    ...recipientFragment
    __typename
  }
  bcc {
    ...recipientFragment
    __typename
  }
}
`

export const attachmentFragment = () => `
fragment attachmentFragment on Attachment {
  __typename
  id
  createdAt
  url
  fileName
  fileSize
  fileType
  url
  downloadUrl
  scanReport {
    status
    matches
  }
}
`

export const conversationAttachmentFragment = () => `
fragment conversationAttachmentFragment on ConversationAttachment {
  id
  ticketId
  commentId
  changesetId
  createdAt
  fileType
  fileName
  fileSize
  url
  downloadUrl
  type
  scanReport {
    status
    matches
  }
  creator {
    name
  }
}
`

export const actorFragment = () => `
fragment actorFragment on Actor {
  ... on Contact {
    id
    name
    email
    role
    avatarUrl
    __typename
  }
  ... on Agent {
    id
    name
    email
    role
    avatarUrl
    __typename
  }
  ... on Rule {
    id
    name
    __typename
  }
  ... on Integration {
    id
    name: provider
    __typename
  }
}
`

export const eventFragment = () => `
fragment eventFragment on Event {
  id
  actor {
    ...actorFragment
  }
  change {
    ... on SubjectChanged {
      id
      from
      __typename
    }
    ... on ContactOpenedMessage {
      id
      __typename
    }
    ... on IntegrationAdded {
      id
      externalId
      provider
      title
      url
      __typename
    }
    ... on IntegrationRemoved {
      id
      externalId
      provider
      title
      url
      __typename
    }
    ... on IntegrationExternalChange {
      id
      provider
      externalId
      action
      __typename
    }
    ... on ConversationMerged {
      id
      __typename
    }
    ... on ShopifyExternalChange {
      changeType
      link
      meta
      __typename
    }
    ... on ConversationDeleted {
      id
      __typename
    }
    ... on EmailMessage {
      ...emailMessageFragment
      __typename
    }
    ... on WidgetMessage {
      ...widgetFragment
      __typename
    }
    ... on Reply {
      ...replyFragment
      __typename
    }
    ... on ForwardedMessage {
      ...forwardFragment
      __typename
    }
    ... on Note {
      ...noteFragment
      __typename
    }
    ... on StateChanged {
      id
      from
      to
      createdAt
      __typename
    }
    ... on Snoozed {
      id
      until
      __typename
    }
    ... on Unsnoozed {
      id
      __typename
    }
    ... on ContactChange {
      id
      from {
        id
        name
        email
        __typename
      }
      to {
        id
        name
        email
        __typename
      }
      createdAt
      __typename
    }
    ... on AgentChanged {
      id
      to {
        id
        name
        __typename
      }
      createdAt
      __typename
    }
    ... on TeamChanged {
      id
      to {
        id
        name
        __typename
      }
      createdAt
      __typename
    }
    ... on ChannelChanged {
      id
      from {
        id
        name
      }
      to {
        id
        name
      }
      createdAt
      __typename
    }
    ... on ConversationStarred {
      id
      createdAt
      __typename
    }
    ... on ConversationUnstarred {
      id
      createdAt
      __typename
    }
    ... on Tagged {
      tag {
        id
        name
      }
      __typename
    }
    ... on Untagged {
      tag {
        id
        name
      }
      __typename
    }
    ... on CommentDeleted {
      id
      __typename
    }
    ... on CommentReacted {
      id
      reaction
      isAdded
      __typename
    }
    ... on Rating {
      id
      feedback
      grade
      agent {
        id
      }
      __typename
    }
    __typename
  }
  __typename
}
`

// CONVERTME Need to reduce the amount of data im selecting here to only include
// the data require for the confirmation list. The doFetchTicket will hydrate the remaining
// information when it loads
export const conversationFragment = ({
  enableCustomFieldFilter = false,
} = {}) => `
fragment conversationFragment on Conversation {
  __typename
  id
  assigned {
    agent {
      id
      name
      email
    }
    team {
      id
      name
    }
    at
  }
  channel {
    id
    color
  }
  contact {
    id
    avatarUrl
    email
    firstName
    lastName
    name
    lastSeenAt
    createdAt
    customFieldValues(filter: { keys: ["contact_twitter", "contact_facebook"] }) {
      edges {
        node {
          ...customFieldValueFragment
        }
      }
    }
  }
    customFieldValues${
      enableCustomFieldFilter
        ? '(filter: { customFieldIds: $customFieldIds })'
        : ''
    } {
    edges {
      node {
        ...customFieldValueFragment
      }
    }
  }
  drafts {
    edges {
      node {
        id
        agent {
          id
        }
        draftId
        draftType
        __typename
      }
    }
  }
  followers {
    nodes {
      id
      name
      email
    }
  }
  deletedAt
  number
  searchSummary {
    author
    body
    forwarded
    note
  }
  snoozed {
    by {
      id
    }
    until
  }
  starred
  state
  stateUpdatedAt
  summaryMessage {
    bodyPlainText
    isNote
    author {
      ... on Agent {
        id
      }
      ... on Contact {
        id
      }
    }
  }
  systemUpdatedAt
  updatedAt
  subject
  lastUnansweredUserMessageAt
  tags {
    nodes {
      id
      name
      color
    }
  }
  counts {
    messages
    interactions
    attachments
  }
}`

export const widgetConversationFragment = () => `
fragment widgetConversationFragment on WidgetConversation {
  ...conversationFragment
  browser
  pageTitle
  pageUrl
  platform
  referrer
}
`

export const allConversationFragments = () => `
  ...conversationFragment
  ...widgetConversationFragment
`

export const eventGroupFragment = () => `
fragment eventGroupFragment on EventGroupType {
  id
  collapsed
  fromMerge
  isNote
  isForward
  hasAttachments
  conversationId
  changesetId,
  actor {
    ...actorFragment
  }
  summary {
    id
    bodyPlainText
    editedAt
    __typename
  }
  events {
    edges {
      node {
        ...eventFragment
      }
      __typename
    }
  }

}
`
