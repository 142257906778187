import { buildId } from 'util/globalId'
import { isChatChannelType } from 'ducks/channels/channelTypes'
import { doGraphqlRequest } from 'ducks/requests/operations'
import { selectAccountCanSeePinnedSearches } from 'selectors/app'
import { selectCurrentUserGlobalId } from 'ducks/currentUser/selectors/selectCurrentUserGlobalId'
import { FETCH_CONVERSATION_COUNTS_FOR_CHANNEL } from '../actionTypes'
import {
  ticketConversationCountForChannelQuery,
  ticketConversationCountQuery,
  roomConversationCountForChannelQuery,
} from '../queries'

const normalizeChannelAndGlobal = data => {
  if (data.node) {
    return {
      folders: data.node.folders || { nodes: [] },
      agents: data.node.permittedAgents || { nodes: [] },
      tags: data.node.tags || { nodes: [] },
      channels: data.node.channels || { nodes: [] },
      pinnedSearches: data.node.pinnedSearches || { nodes: [] },
    }
  }
  return {
    folders: data.folders || { nodes: [] },
    agents: data.agents || { nodes: [] },
    tags: data.tags || { nodes: [] },
    channels: data.channels || { nodes: [] },
    pinnedSearches: data.pinnedSearches || { nodes: [] },
  }
}

function hackFolderCountsResponse(channelType) {
  return data => {
    if (isChatChannelType(channelType)) {
      const { roomsCounts: { lastUpdatedAt, data: folders } = {} } = data || {}
      return {
        folders: {
          // IMPORTANT: We dont currently have this available for the ticket conversation
          // counts. We'll need to extend the api to also expose this
          lastUpdatedAt,
          nodes: folders,
        },
        agents: { nodes: [] },
        tags: { nodes: [] },
        channels: { nodes: [] },
        pinnedSearches: { nodes: [] },
      }
    }
    const normalized = normalizeChannelAndGlobal(data)
    normalized.folders.lastUpdatedAt =
      normalized.folders.lastUpdatedAt || Date.now()
    return normalized
  }
}

/*
  This method will be used to retrieve the conversation counts for a particular channel.
  Currently there is a massive disparity in the api implementation between tickets and
  rooms. This method will hide those differences and return a standadised interface for
  the folder component.
 */
export const doFetchFolderCounts = ({ channelId, channelType }) => (
  dispatch,
  getState
) => {
  const state = getState()
  const isChat = isChatChannelType(channelType)
  const currentUserId = selectCurrentUserGlobalId(state)
  let query = roomConversationCountForChannelQuery()
  const canUsePinnedSearches = selectAccountCanSeePinnedSearches(state)
  if (!isChat) {
    if (channelId) {
      query = ticketConversationCountForChannelQuery({ canUsePinnedSearches })
    } else {
      query = ticketConversationCountQuery({ canUsePinnedSearches })
    }
  }
  return dispatch(
    doGraphqlRequest(
      FETCH_CONVERSATION_COUNTS_FOR_CHANNEL,
      query,
      {
        channelId: buildId('Channel', channelId),
        channelType: channelType?.toUpperCase(),
        pinnedSearchFilter: {
          agentId: currentUserId,
        },
      },
      {
        app: true,
        transformResponse: hackFolderCountsResponse(channelType),
        meta: {
          channelType,
        },
      }
    )
  )
}
