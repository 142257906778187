import { normalize } from 'normalizr'
import { v4 as uuidV4, validate as validateUUID } from 'uuid'
import { selectSearchByQueryId } from 'ducks/searches/selectors'
import { createDoFetchInMemoryByQueryId } from 'ducks/searches/operations/createDoFetchInMemoryByQueryId'
import {
  doAttachementRequest,
  doGraphqlRequest,
} from 'ducks/requests/operations'
import { selectCurrentRules } from 'ducks/rules/selectors'
import { getRawId, isGid } from 'util/globalId'
import { isEmpty, omit } from 'util/objects'
import { rule as ruleEntity } from 'ducks/entities/schema'
import {
  changeEntity,
  clearEntities,
  mergeEntityChanges,
} from 'ducks/entities/actionUtils'
import { stringToColor } from 'util/colors'
import { isDefined, isNullOrUndefined } from 'util/nullOrUndefinedChecks'
import { doCreateUpdateTagV2 } from 'ducks/tags/actions'
import { doTryFetchAccountUsageOnboardingForOnboarding } from 'ducks/accountPreferences/operations'
import { selectFeatureBasedOnboardingWorkflowData } from 'subapps/onboarding/selectors'
import { selectCustomFieldsByKey } from 'ducks/crm/customFields/selectors/base'

import {
  FETCH_RULES,
  FETCH_RULE,
  DELETE_RULE,
  UPDATE_RULE,
  UPLOAD_RULE_ACTION_ATTACHMENT,
  SAVE_RULE_DRAFT,
  CLEAR_ALL_RULE_DRAFTS,
} from './actionTypes'
import {
  rulesResponseSchema,
  ruleFetchSchema,
  ruleUpdateSchema,
  ruleCreateSchema,
} from './schema'
import {
  createRuleMutation,
  deleteRuleMutation,
  fetchRuleQuery,
  fetchRulesQuery,
  updateRuleMutation,
} from './queries'
import { LOAD_ALL_RULES_QUERYID } from './constants'

export const doFetchRules = ({ skipLoaded } = {}) => (dispatch, getState) => {
  const queryId = LOAD_ALL_RULES_QUERYID
  const cursor = 'all'
  const state = getState()
  const { loaded = null, cursors = {} } = selectSearchByQueryId(state, queryId)
  const hasCurrentPage = !!cursors[cursor]
  const isStale = hasCurrentPage && cursors[cursor].isStale

  const hasLoaded = loaded && hasCurrentPage && !isStale

  if (hasLoaded && skipLoaded) {
    // Note we might need to change this in future to return the results
    // from the previous query
    return Promise.resolve({})
  }

  return dispatch(
    doGraphqlRequest(
      FETCH_RULES,
      fetchRulesQuery(),
      {},
      {
        normalizationSchema: rulesResponseSchema,
        app: true,
        searches: {
          queryId,
          cursor,
          extractPagination: 'gqlv2',
        },
      }
    )
  )
}

export const doFetchRulesById = (id, options = {}) => dispatch => {
  // targetStore: optional, defaults to changeEntity()'s default store otherwise
  const { targetStore } = options

  return dispatch(
    doGraphqlRequest(
      FETCH_RULE,
      fetchRuleQuery(),
      {
        ruleId: id,
      },
      {
        normalizationSchema: ruleFetchSchema,
        app: true,
        moduleOptions: {
          entities: {
            targetStore,
            missingEntityActions: [
              {
                entityType: 'rule',
                // convert from gid to scatter swapped that's used in entity store
                entityId: id,
                phases: ['SUCCESS'],
              },
            ],
          },
        },
      }
    )
  )
}

export const doFetchRulesInMemory = createDoFetchInMemoryByQueryId({
  fromQueryId: LOAD_ALL_RULES_QUERYID,
  entityType: 'rule',
  doLoadAllFn: doFetchRules,
})

// eslint-disable-next-line no-unused-vars
export const doDeleteRuleById = (id, options = {}) => dispatch => {
  return dispatch(
    doGraphqlRequest(
      DELETE_RULE,
      deleteRuleMutation(),
      {
        ruleId: id,
      },
      {
        app: true,
        optimist: {},
        searches: {
          additionalActions: [
            {
              type: 'INVALIDATE_ENTITIES',
              entityTypes: ['rule'],
              phases: ['SUCCESS'],
            },
          ],
        },
        moduleOptions: {
          toasts: {
            enabled: true,
            started: {
              enabled: true,
              content: 'Rule deleted',
            },
            success: {
              enabled: false,
            },
            failed: {
              content: 'Rule deletion failed',
              onClickAction: () => {
                dispatch(doDeleteRuleById(id, options))
              },
            },
          },
          entities: {
            targetOperation: 'remove',
            additionalActions: [
              {
                // Because we're passing through a global id here, we need to manually delete
                // the entity from the store
                entityType: 'rule',
                entityId: id,
                stores: ['pending', 'current'],
                operation: 'remove',
                phases: ['STARTED', 'SUCCESS'],
              },
            ],
          },
        },
      }
    )
  )
}

function calculateRulePosition(rule, currentOrderedRules) {
  if (currentOrderedRules[rule.row_order + 1]) {
    return currentOrderedRules[rule.row_order + 1].row_order - 10
  } else if (currentOrderedRules[rule.row_order - 1]) {
    return currentOrderedRules[rule.row_order - 1].row_order + 10
  }
  return rule.row_order
}

const prepareRule = async (rule, { dispatch, getState }) => {
  const id = rule.id

  const customFieldsByKey = selectCustomFieldsByKey(getState())
  const actions = []
  const uploadAttachments = []
  const ruleActionTagsToCreate = []

  const conditions = rule.conditions.map(c => {
    const condition = { ...c }
    if (condition.param === 'CUSTOM_FIELD') {
      condition.sourceId = condition.source.id
    }
    delete condition.source
    if (!c.param.startsWith('CUSTOM_FIELD_')) return condition

    return Object.assign(condition, {
      param: 'CUSTOM_FIELD',
      sourceId: customFieldsByKey[c.param.replace('CUSTOM_FIELD_', '')].id,
    })
  })

  rule.actions.forEach((action, actionIndex) => {
    const messageTemplate = action.messageTemplate
    const ruleReplyTemplateId = action.replyTemplate?.id || action.replyTemplate
    const sanitizedAction = omit(['messageTemplate', 'replyTemplate'], action)

    if (messageTemplate) {
      const { attachments, body, subject } = messageTemplate

      let { pendingUploads } = messageTemplate

      if (pendingUploads) {
        pendingUploads = pendingUploads.map(pendingUpload => ({
          ...pendingUpload,
          actionIndex,
        }))
      }

      const actionMessageTemplate = {
        body,
        subject,
      }

      if (ruleReplyTemplateId && !validateUUID(ruleReplyTemplateId)) {
        // we're updating an existing rule message template
        actionMessageTemplate.id = ruleReplyTemplateId
      }

      if (Array.isArray(attachments)) {
        actionMessageTemplate.attachmentIds = attachments
          .filter(attachmentId => {
            if (!pendingUploads) {
              return true
            }

            const pendingUpload = pendingUploads.find(p => {
              return p.attachmentId === attachmentId
            })
            return !pendingUpload
          })
          .map(attachmentId => attachmentId)
      }

      if (Array.isArray(pendingUploads) && pendingUploads.length > 0) {
        uploadAttachments.push(...pendingUploads)
      }

      sanitizedAction.replyTemplate = actionMessageTemplate
      // keep action.value to be the same as message template body so it's backwards compatible with old settings screen
      sanitizedAction.value = body
    }

    if (isDefined(action.id) && validateUUID(action.id)) {
      // is a temp id created from saving draft
      delete sanitizedAction.id
    }

    const { type, value } = action

    if (type === 'LABELS' && !!value && !!value.trim()) {
      const tags = (value || '').split(',')
      tags.forEach((tagName, tagIndex) => {
        if (tagName && !isGid(tagName)) {
          ruleActionTagsToCreate.push({
            actionIndex,
            tagIndex,
            tagName,
          })
        }
      })
    }

    actions.push(sanitizedAction)
  })

  if (ruleActionTagsToCreate.length) {
    const tagsCreated = await Promise.all(
      ruleActionTagsToCreate.map(async ({ tagName }) => {
        return dispatch(
          doCreateUpdateTagV2(
            'new',
            {
              name: tagName,
              color: stringToColor(tagName).toUpperCase(),
            },
            {
              toastsEnabled: false,
            }
          )
        )
      })
    )

    const tagsCreatedByName = tagsCreated.reduce((acc, obj) => {
      const { tagCreate: { tag } = {} } = obj || {}
      if (!tag) return acc

      // eslint-disable-next-line no-param-reassign
      acc[tag.name] = tag

      return acc
    }, {})

    ruleActionTagsToCreate.forEach(({ actionIndex, tagIndex, tagName }) => {
      const action = actions[actionIndex]
      if (action && !!action.value) {
        const tags = (action.value || '').split(',')
        if (tags.length >= tagIndex) {
          const createdTag = tagsCreatedByName[tagName]

          if (createdTag) {
            tags[tagIndex] = createdTag.id

            actions[actionIndex].value = tags.join(',')
          }
        }
      }
    })
  }

  const payload = {
    ruleId: id !== 'new' ? id : null,
    state: rule.active ? 'ACTIVE' : 'INACTIVE',
    matchType: rule.matchType,
    name: rule.name,
    stopUpcoming: rule.stopUpcoming,
    actions,
    conditions,
    triggers: rule.triggers,
    scheduleType: rule.scheduleType,
    scheduleSettings: rule.scheduleSettings,
  }

  return { payload, uploadAttachments, ruleActionTagsToCreate }
}

export const doUploadRuleAttachments = (
  responseRule,
  uploadAttachments,
  options
) => dispatch => {
  if (!responseRule || uploadAttachments.length === 0) return null

  return Promise.all(
    responseRule.actions.edges.map(async (edge, actionIndex) => {
      const action = edge.node
      if (!action.replyTemplate) {
        return null
      }

      const ruleTemplateAttachmentsToUpload = uploadAttachments.filter(
        u => u.actionIndex === actionIndex
      )
      const ruleReplyTemplateId = getRawId(action.replyTemplate.id)
      return dispatch(
        doAttachementRequest(
          UPLOAD_RULE_ACTION_ATTACHMENT,
          `/rule_reply_templates/${ruleReplyTemplateId}/attachments`,
          ruleTemplateAttachmentsToUpload.map(upload => upload.editorFile),
          options
        )
      )
    })
  )
}

export const doUpdateRule = (rule, options = {}) => async (
  dispatch,
  getState
) => {
  const { updatePosition, queryId, orderedIds = null } = options
  const state = getState()
  const orderedRules = selectCurrentRules(state)

  const { payload, uploadAttachments } = await prepareRule(rule, {
    dispatch,
    getState,
  })

  if (updatePosition) {
    payload.position = rule.position
  }

  const optimisticRule = {
    ...rule,
  }
  if (updatePosition) {
    optimisticRule.position = calculateRulePosition(rule, orderedRules)
  }

  const normalizedEntities = normalize(rule, ruleEntity)

  const optimist = {
    entities: normalizedEntities.entities,
  }

  const response = await dispatch(
    doGraphqlRequest(UPDATE_RULE, updateRuleMutation(), payload, {
      app: true,
      optimist,
      throwOnError: true,
      normalizationSchema: ruleUpdateSchema,
      searches: {
        additionalActions: [
          {
            type: 'INVALIDATE_ENTITIES',
            entityTypes: Object.keys(optimist.entities),
            phases: ['SUCCESS'],
          },
          {
            type: 'UPDATE_CURSOR',
            queryId,
            entityIds: orderedIds,
            phases: ['STARTED'],
          },
        ],
      },
      moduleOptions: {
        entities: {
          additionalActions: Object.keys(optimist.entities).map(entityType => {
            return {
              entityType,
              stores: ['pending'],
              phases: ['SUCCESS'],
              type: 'clear',
            }
          }),
        },
        toasts: {
          enabled: true,
          started: {
            enabled: false,
          },
          success: {
            enabled: true,
            content: 'Rule updated',
          },
          failed: {
            content: 'Rule update failed',
            onClickAction: () => {
              dispatch(doUpdateRule(rule, options))
            },
          },
        },
      },
    })
  )

  await dispatch(
    doUploadRuleAttachments(
      response?.ruleUpdate?.rule,
      uploadAttachments,
      options
    )
  )

  return response
}

export const doCreateRule = (rule, options = {}) => async (
  dispatch,
  getState
) => {
  const { payload, uploadAttachments } = await prepareRule(rule, {
    dispatch,
    getState,
  })

  const normalizedEntities = normalize(rule, ruleEntity)

  const optimist = {
    entities: normalizedEntities.entities,
  }

  const response = await dispatch(
    doGraphqlRequest(UPDATE_RULE, createRuleMutation(), payload, {
      app: true,
      optimist,
      throwOnError: true,
      normalizationSchema: ruleCreateSchema,
      searches: {
        additionalActions: [
          {
            type: 'INVALIDATE_ENTITIES',
            entityTypes: Object.keys(optimist.entities),
            phases: ['SUCCESS'],
          },
        ],
      },
      moduleOptions: {
        entities: {
          additionalActions: Object.keys(optimist.entities).map(entityType => {
            return {
              entityType,
              stores: ['pending'],
              phases: ['SUCCESS'],
              type: 'clear',
            }
          }),
        },
        toasts: {
          enabled: true,
          started: {
            enabled: false,
          },
          success: {
            enabled: true,
            content: 'Rule created',
          },
          failed: {
            content: 'Rule create failed',
            onClickAction: () => {
              dispatch(doCreateRule(rule, options))
            },
          },
        },
      },
      ...options,
    })
  )

  await dispatch(
    doUploadRuleAttachments(
      response?.ruleCreate?.rule,
      uploadAttachments,
      options
    )
  )

  const onboardingWorkflowData = selectFeatureBasedOnboardingWorkflowData(
    getState()
  )
  dispatch(
    doTryFetchAccountUsageOnboardingForOnboarding(
      onboardingWorkflowData.rule?.usageKey
    )
  )
  return dispatch(doFetchRules())
}

export const doDeleteRules = (ids, options = {}) => dispatch => {
  // Promise.all is not technically required in the current implementation because
  // we'll only step into this block if the ids array has a single id, but I do
  // want to leave the door open for us to say that deleting less than X rows uses
  // single deletes instead of batch deletes
  return Promise.all(ids.map(id => dispatch(doDeleteRuleById(id, options))))
}

export const doClearAllRuleDrafts = () => {
  return {
    type: CLEAR_ALL_RULE_DRAFTS,
    ...mergeEntityChanges([
      clearEntities('rule', 'pending'),
      clearEntities('ruleAction', 'pending'),
      clearEntities('ruleReplyTemplate', 'pending'),
      clearEntities('ruleCondition', 'pending'),
      clearEntities('ruleTrigger', 'pending'),
    ]),
  }
}

export const doSaveRuleDraft = (id, fields) => dispatch => {
  if (isEmpty(fields)) return null

  const ruleFields = omit(['actions', 'conditions', 'triggers'], fields)
  const actionsIds = []
  const conditionIds = []
  const triggerIds = []

  const entityChanges = []

  if (fields?.actions?.length) {
    fields.actions.forEach(action => {
      const sanitizedAction = omit(['messageTemplate'], action)

      if (isNullOrUndefined(action.id)) {
        sanitizedAction.id = uuidV4()
      }
      actionsIds.push(sanitizedAction.id)

      const messageTemplate = action.messageTemplate

      if (messageTemplate) {
        if (isNullOrUndefined(action.rule_reply_template)) {
          const templateId = uuidV4()
          sanitizedAction.rule_reply_template = templateId
        }

        if (isNullOrUndefined(messageTemplate.id)) {
          messageTemplate.id = sanitizedAction.rule_reply_template
        }

        sanitizedAction.value = messageTemplate?.body

        entityChanges.push(
          changeEntity(
            'ruleReplyTemplate',
            messageTemplate.id,
            messageTemplate,
            'update',
            'pending'
          )
        )
      }

      entityChanges.push(
        changeEntity(
          'ruleAction',
          sanitizedAction.id,
          sanitizedAction,
          'update',
          'pending'
        )
      )
    })
  }

  if (fields?.conditions?.length) {
    fields.conditions.forEach(condition => {
      const sanitizedCondition = condition

      if (isNullOrUndefined(condition.id)) {
        sanitizedCondition.id = uuidV4()
      }
      conditionIds.push(sanitizedCondition.id)

      entityChanges.push(
        changeEntity(
          'ruleCondition',
          sanitizedCondition.id,
          sanitizedCondition,
          'update',
          'pending'
        )
      )
    })
  }

  if (fields?.triggers?.length) {
    fields.triggers.forEach(trigger => {
      const sanitizedTrigger = trigger

      if (isNullOrUndefined(trigger.id)) {
        sanitizedTrigger.id = uuidV4()
      }
      triggerIds.push(sanitizedTrigger.id)

      entityChanges.push(
        changeEntity(
          'ruleTrigger',
          sanitizedTrigger.id,
          sanitizedTrigger,
          'update',
          'pending'
        )
      )
    })
  }

  ruleFields.actions = actionsIds
  ruleFields.conditions = conditionIds
  ruleFields.triggers = triggerIds
  // to allow us keep default values
  ruleFields.isDraft = true

  entityChanges.push(changeEntity('rule', id, ruleFields, 'update', 'pending'))

  return dispatch({
    type: SAVE_RULE_DRAFT,
    ...mergeEntityChanges(entityChanges),
  })
}
