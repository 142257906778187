/* eslint-disable no-param-reassign */
import createCachedSelector from 're-reselect'
import { selectConversationEventsByGroupId } from '.'

export const selectReactionsByAgentIdForEventGroupId = createCachedSelector(
  selectConversationEventsByGroupId,
  events => {
    return events.reduce((byAgentId, event) => {
      // eslint-disable-next-line no-underscore-dangle
      const isReaction = event.change.__typename === 'CommentReacted'
      if (!isReaction) return byAgentId

      const {
        change: { isAdded, reaction },
        actor: { id: agentId },
      } = event

      if (!byAgentId[agentId]) byAgentId[agentId] = {}

      if (!isAdded && byAgentId[agentId][reaction]) {
        delete byAgentId[agentId][reaction]
      } else if (isAdded) {
        byAgentId[agentId][reaction] = event
      }

      return byAgentId
    }, {})
  }
)((_state, groupId) => groupId || 'unknown')
