import { createActionTypeReducer } from 'util/reducers'
import {
  UNDO_SEND_FAILED,
  UNDO_SEND_STARTED,
  UNDO_SEND_SUCCESS,
  UNDO_SEND_TIMER_END,
  UNDO_SEND_TIMER_START,
} from './actionTypes'

const initialState = {
  isActive: false,
  changesetId: null,
  ticketId: null,
  status: null,
  draft: null,
  draftId: null,
}

export default createActionTypeReducer(
  {
    [UNDO_SEND_FAILED]: draftState => {
      draftState.status = 'failed'
      return draftState
    },
    [UNDO_SEND_SUCCESS]: draftState => {
      draftState.status = 'succeeded'
      return draftState
    },
    [UNDO_SEND_STARTED]: draftState => {
      draftState.status = 'undoing'
      return draftState
    },
    [UNDO_SEND_TIMER_END]: draftState => {
      return Object.assign(draftState, initialState)
    },
    [UNDO_SEND_TIMER_START]: (draftState, { payload }) => {
      return Object.assign(draftState, {
        ...initialState,
        changesetId: payload.changesetId,
        draft: payload.draft,
        draftId: payload.draft.id,
        ticketId: payload.ticketId,
        isActive: true,
        status: 'sending',
      })
    },
  },
  initialState
)
