import { createActionTypeReducer } from 'util/reducers'
import { CHAT_PAGE, SOCIAL_PAGE } from 'subapps/chat/pages'
import { queryStringToQueryId } from 'ducks/searches/utils/query'
import {
  SEARCH_PAGE,
  TICKET_COMMENT_PAGE,
  TICKET_PAGE,
  TICKETS_PAGE,
  MAILBOX_PERSIST_KEY,
  NEW_CONVERSATION_PAGE,
  LOG_CONVERSATION_PAGE,
} from 'constants/pages'
import storage from 'util/storage'

const currentQueryIdFromQueryReducer = persistKey => (_, action) => {
  // https://github.com/faceyspacey/redux-first-router/blob/master/docs/query-strings.md#where-query-lives-on-your-actions
  const { meta: { location: { current: { query = {} } = {} } = {} } = {} } =
    action || {}

  const queryId = queryStringToQueryId(query)
  if (persistKey && queryId) {
    storage.set(persistKey, queryId)
  }

  return queryId
}

export const currentQueryId = createActionTypeReducer(
  {
    [CHAT_PAGE]: currentQueryIdFromQueryReducer(null),
    [SOCIAL_PAGE]: currentQueryIdFromQueryReducer(null),
    [SEARCH_PAGE]: currentQueryIdFromQueryReducer(null),
    [TICKETS_PAGE]: currentQueryIdFromQueryReducer(MAILBOX_PERSIST_KEY),
    [TICKET_PAGE]: currentQueryIdFromQueryReducer(MAILBOX_PERSIST_KEY),
    [TICKET_COMMENT_PAGE]: currentQueryIdFromQueryReducer(MAILBOX_PERSIST_KEY),
    [LOG_CONVERSATION_PAGE]: currentQueryIdFromQueryReducer(
      MAILBOX_PERSIST_KEY
    ),
    [NEW_CONVERSATION_PAGE]: currentQueryIdFromQueryReducer(
      MAILBOX_PERSIST_KEY
    ),
  },
  null
)
