import merge from 'deepmerge'
import {
  LONGEST_UNANSWERED,
  NEWEST,
  NEWEST_BY_CLOSED,
  NEWEST_BY_COLLABORATOR,
  NEWEST_BY_DELETED,
  NEWEST_BY_SPAM,
  OLDEST,
  OLDEST_BY_COLLABORATOR,
} from 'constants/defaults'
import createCachedSelector from 're-reselect'
import {
  byLongestUnanswered,
  byNewest,
  byNewestClosed,
  byNewestDeleted,
  byNewestSpam,
  byOldest,
  sortOrderContextKeyToSortOptionKeys,
} from 'util/search/sorting'
import Link from 'redux-first-router-link'
import { selectCurrentAction } from 'selectors/location'
import { selectAccountPreferenceSortByLastUnansweredUserMessageAtEnabled } from 'selectors/app/selectAccountPreferences'
import { selectSortContextIdByQueryId } from './selectSortContextIdByQueryId'
import { selectQueryByQueryId } from '.'

export const selectSortOptionsByQueryId = createCachedSelector(
  (state, queryId) => selectQueryByQueryId(state, queryId),
  (state, queryId) => selectSortContextIdByQueryId(state, queryId),
  selectAccountPreferenceSortByLastUnansweredUserMessageAtEnabled,
  selectCurrentAction,
  (query, contextId, sortByLastUnansweredUserMessageAtEnabled, currentPage) => {
    const options = []
    const contextOptions = sortOrderContextKeyToSortOptionKeys(contextId)
    const sortOrder = query.orderBy

    contextOptions.forEach(orderKey => {
      if (NEWEST_BY_CLOSED === orderKey) {
        options.push({
          active: byNewestClosed(sortOrder),
          as: Link,
          key: orderKey,
          text: 'Recently Closed',
          selectedOptionText: 'Recently Closed',
          to: merge(currentPage, {
            meta: {
              query: {
                orderBy: orderKey,
              },
            },
          }),
          value: orderKey,
        })
      }

      if (NEWEST_BY_SPAM === orderKey) {
        options.push({
          active: byNewestSpam(sortOrder),
          as: Link,
          key: orderKey,
          text: 'Recently Spammed',
          selectedOptionText: 'Recently Spammed',
          to: merge(currentPage, {
            meta: {
              query: {
                orderBy: orderKey,
              },
            },
          }),
          value: orderKey,
        })
      }

      if (NEWEST_BY_DELETED === orderKey) {
        options.push({
          active: byNewestDeleted(sortOrder),
          as: Link,
          key: orderKey,
          text: 'Recently Trashed',
          selectedOptionText: 'Recently Trashed',
          to: merge(currentPage, {
            meta: {
              query: {
                orderBy: orderKey,
              },
            },
          }),
          value: orderKey,
        })
      }

      if (
        LONGEST_UNANSWERED === orderKey &&
        sortByLastUnansweredUserMessageAtEnabled
      ) {
        options.push({
          active: byLongestUnanswered(sortOrder),
          as: Link,
          key: orderKey,
          text: 'Waiting Longest',
          selectedOptionText: 'Waiting Longest',
          to: merge(currentPage, {
            meta: {
              query: {
                orderBy: orderKey,
              },
            },
          }),
          value: orderKey,
        })
      }

      if ([NEWEST, NEWEST_BY_COLLABORATOR].includes(orderKey)) {
        options.push({
          active: byNewest(sortOrder),
          as: Link,
          key: orderKey,
          text: 'Newest',
          selectedOptionText: 'Newest first',
          to: merge(currentPage, {
            meta: {
              query: {
                orderBy: orderKey,
              },
            },
          }),
          value: orderKey,
        })
      }

      if ([OLDEST, OLDEST_BY_COLLABORATOR].includes(orderKey)) {
        options.push({
          active: byOldest(sortOrder),
          as: Link,
          key: orderKey,
          text: 'Oldest',
          selectedOptionText: 'Oldest first',
          to: merge(currentPage, {
            meta: {
              query: {
                orderBy: orderKey,
              },
            },
          }),
          value: orderKey,
        })
      }
    })

    return options
  }
)((_state, queryId) => queryId || 'unknown')
