import { selectPrefersClassicView } from 'ducks/currentUser/selectors/preferences/selectPrefersClassicView'
import { selectPrefersOpenFolderOnTicketSave } from 'ducks/currentUser/selectors/preferences/selectPrefersOpenFolderOnTicketSave'
import {
  selectSearchNextEntityIdByQueryIdAndEntityId,
  selectSearchHasMoreByQueryId,
  selectSearchIsLoadingMoreByQueryId,
  // selectSearchEntityIdsByQueryId,
} from 'ducks/searches/selectors'
import { selectCurrentQueryId } from 'ducks/searches/selectors/selectCurrentQueryId'
import { doOpenTicketPage } from 'actions/pages'
import { selectAutoAdvanceToNextTicketInList } from 'ducks/currentUser/selectors/preferences/selectAutoAdvanceToNextTicketInList'
import { selectFolderRouteAction } from '../selectors/selectFolderRouteAction'
import { doOpenNextTicketPage } from '../actions/doOpenNextTicketPage'
import { doFetchTickets } from '../actions'
import { selectCurrentTicketId } from '../selectors/selectCurrentTicketId'

// Ensure the logic in here matches the logic in selectWillAutoRedirectForTicketId
export const doAutoRedirect = (ticketId, options = {}) => async (
  dispatch,
  getState
) => {
  const state = getState()
  const forced = options.forced || false
  const queryId = selectCurrentQueryId(state)
  const classicView = selectPrefersClassicView(state)
  const shouldOpenFolder = selectPrefersOpenFolderOnTicketSave(state)
  const linkToFolder = selectFolderRouteAction(state)
  const nextTicketId = selectSearchNextEntityIdByQueryIdAndEntityId(
    state,
    queryId,
    ticketId
  )
  const hasMore = selectSearchHasMoreByQueryId(state, queryId)
  const isLoadingMore = selectSearchIsLoadingMoreByQueryId(state, queryId)
  const shouldAdvance = selectAutoAdvanceToNextTicketInList(state)
  const currentTicketId = selectCurrentTicketId(state)
  // const currentTicketIds = selectSearchEntityIdsByQueryId(state, queryId)
  // const isStillInCurrentQueryList = currentTicketIds.includes(ticketId)

  if (ticketId && currentTicketId !== ticketId && forced) {
    dispatch(doOpenTicketPage(ticketId, { preserveQuery: true }))
  } else if (shouldAdvance || forced) {
    if (nextTicketId) {
      // Kevin R: I dont know what isStillInCurrentQueryList is used for :/
      // Leaving this code here until we figure out what it breaks.
      // I needed to remove it in order for the doDelete redirect to work
      // when the agent has auto-advance disabled.
      // if (!isStillInCurrentQueryList && ticketId === currentTicketId) {
      dispatch(doOpenTicketPage(nextTicketId, { preserveQuery: true }))
      // }
    } else if (hasMore && !isLoadingMore) {
      await dispatch(doFetchTickets())
      dispatch(doOpenNextTicketPage())
    }
  } else if ((classicView && shouldOpenFolder) || forced) {
    dispatch(linkToFolder)
  }
  return null
}
