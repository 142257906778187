import emailParser from 'email-addresses'

// The backend seems to throw nil bombs when empty arrays are passed along which seems wrong.
// This will work around the issue for now, but we should really fix that up.
export const arrayOrNull = array => {
  if (!array) return null
  if (!Array.isArray(array)) return null
  if (array.length === 0) return null

  return array
}

export const emailToEmailRecipientInput = email => {
  const item = emailParser.parseOneAddress(email)
  return { email: item.address, name: item.name }
}

export const emailArrayToEmailRecipientInputArray = arrayOfEmails => {
  if (!arrayOfEmails) return null
  if (!Array.isArray(arrayOfEmails)) return null

  return arrayOfEmails.map(emailToEmailRecipientInput)
}
