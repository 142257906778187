import { mergePlain } from 'util/merge'
import { doGraphqlRequest } from 'ducks/requests/operations'
import { buildId } from 'util/globalId'
import { selectCurrentUserIsViewer } from 'ducks/currentUser/selectors'
import { UPDATE_CONVERSATION_STATE } from '../actionTypes'
import { updateConversationStateMutation } from '../mutations'
import { CONVERSATION_SAVE_UNLOAD_MESSAGE, SNOOZED } from '../constants'
import { conversationStateGraphQlResponseSchema } from '../schema'
import { selectCurrentConversationById } from '../selectors'
import { withAutoRedirect } from '../utils/request'
import { buildConversationOptimistRequestOptions } from '../utils/optimistic'
import { selectWillAutoRedirectForTicketId } from '../selectors/selectWillAutoRedirectForTicketId'

const transformAttachEventGroupIdToEvents = data => {
  const eventGroup =
    data?.conversationUnread?.eventGroup ||
    data?.conversationOpen?.eventGroup ||
    data?.conversationClose?.eventGroup ||
    data?.conversationSpam?.eventGroup ||
    data?.conversationTrash?.eventGroup

  if (eventGroup) {
    eventGroup.isLastMessage = true
    eventGroup.events.edges.forEach(e => {
      // eslint-disable-next-line no-param-reassign
      e.node.eventGroupId = eventGroup.id
    })
  }
  return data
}

export const doUpdateState = (ticketId, newState, options = {}) => async (
  dispatch,
  getState
) => {
  const conversationId = buildId('Conversation', ticketId)

  const state = getState()
  const isCurrentUserViewer = selectCurrentUserIsViewer(state)
  const ticket = selectCurrentConversationById(state, ticketId)
  if (isCurrentUserViewer || !ticket || ticket.state === newState) return null

  const updatedConversation = {
    ...ticket,
    state: newState,
  }
  if (newState !== SNOOZED) {
    updatedConversation.snoozed = null
  }

  const {
    optimist,
    additionalActions,
  } = await buildConversationOptimistRequestOptions(
    getState,
    ticketId,
    {
      conversationId: ticketId,
      state: newState,
    },
    options
  )
  const backHref = window.location.href
  const willAutoRedirect = selectWillAutoRedirectForTicketId(
    getState(),
    ticketId,
    !!options?.moduleOptions?.autoRedirect?.forced
  )

  return dispatch(
    withAutoRedirect(
      ticketId,
      doGraphqlRequest(
        UPDATE_CONVERSATION_STATE,
        updateConversationStateMutation({ state: newState }),
        {
          conversationId,
        },
        mergePlain(
          {
            app: true,
            throwOnError: true,
            concurrency: {
              key: ticketId,
              message: CONVERSATION_SAVE_UNLOAD_MESSAGE,
            },
            normalizationSchema: conversationStateGraphQlResponseSchema,
            transformResponse: transformAttachEventGroupIdToEvents,
            optimist,
            moduleOptions: {
              snackbar: {
                enabled: true,
                success: {
                  enabled: true,
                  message: `${app.t('Ticket')} updated`,
                  link: willAutoRedirect && {
                    href: backHref,
                    text: `Go back to ${app.t('Ticket')}`,
                  },
                },
                failed: {
                  enabled: true,
                  message: `Oops, we couldn't update your ${app.t('Ticket')}.`,
                },
              },
              entities: {
                additionalActions,
              },
            },
            meta: {
              mergeEntities: true,
            },
          },
          options
        )
      ),
      options
    )
  )
}
