import { buildId, getRawId } from 'util/globalId'
import { v4 as uuidV4 } from 'uuid'
import { selectContactForEmail } from 'ducks/crm/contacts/selectors/base'
import {
  selectConversationEventsByGroupId,
  selectCurrentConversationById,
} from 'ducks/tickets/selectors'
import { selectCurrentTagsById } from 'ducks/tags/selectors'
import { selectCurrentUserGlobalId } from 'ducks/currentUser/selectors/selectCurrentUserGlobalId'
import { areArraysEqual, flatten, uniq } from 'util/arrays'
import { selectTicketEventGroupsByConversationId } from 'ducks/tickets/selectors/selectTicketEventGroupsByConversationId'
import { stripHTML } from 'util/strings'
import { selectCurrentAgentsById } from 'selectors/agents/base'
import { selectCurrentTeamsById } from 'ducks/teams/selectors'
import { selectReactionsByAgentIdForEventGroupId } from 'ducks/tickets/selectors/selectReactionsByAgentIdForEventGroupId'
import { selectCurrentChannelsById } from 'ducks/channels/selectors'
import { pick } from 'util/objects'
import { selectConversationEventGroupsByConversationId } from 'ducks/tickets/selectors/selectConversationEventGroupsByConversationId'
import {
  CHANGE_TYPE_AGENT_CHANGED,
  CHANGE_TYPE_CHANNEL_CHANGED,
  CHANGE_TYPE_COMMENT_DELETED,
  CHANGE_TYPE_COMMENT_EDITED,
  CHANGE_TYPE_COMMENT_REACTED,
  CHANGE_TYPE_CONTACT_CHANGED,
  CHANGE_TYPE_CONVERSATION_DELETED,
  CHANGE_TYPE_CONVERSATION_RESTORED,
  CHANGE_TYPE_CONVERSATION_STARRED,
  CHANGE_TYPE_CONVERSATION_UNSTARRED,
  CHANGE_TYPE_FOLLOWER_ADDED,
  CHANGE_TYPE_FOLLOWER_REMOVED,
  CHANGE_TYPE_FORWARDED_MESSAGE,
  CHANGE_TYPE_NOTE_MESSAGE,
  CHANGE_TYPE_REPLY_MESSAGE,
  CHANGE_TYPE_SNOOZED,
  CHANGE_TYPE_STATE_CHANGED,
  CHANGE_TYPE_SUBJECT_CHANGED,
  CHANGE_TYPE_TAGGED,
  CHANGE_TYPE_TEAM_CHANGED,
  CHANGE_TYPE_UNSNOOZED,
  CHANGE_TYPE_UNTAGGED,
  DELETED,
  SNOOZED,
  TRASH,
} from '../../constants'
import { emailToEmailRecipientInput } from '../payload'
import { formatDateForMessage } from '../message'

const DELETED_EVENTS = [TRASH, DELETED]

export const buildOptimisticId = type => {
  return `o:${buildId(type, uuidV4())}`
}

export const buildChange = (type, params = {}) => {
  const date = new Date().toISOString()
  return {
    id: buildOptimisticId(type),
    createdAt: date,
    updatedAt: date,
    __typename: type,
    ...params,
  }
}

export const buildEvent = (eventGroupId, actor, change) => {
  const date = new Date().toISOString()
  return {
    id: buildOptimisticId('Event'),
    actor,
    change,
    createdAt: date,
    formattedCreatedAt: formatDateForMessage(date),
    __typename: 'EventEdge',
    eventGroupId,
  }
}

export const buildAgentChange = (fromAgent, toAgent) => {
  return buildChange(CHANGE_TYPE_AGENT_CHANGED, {
    from: fromAgent,
    to: toAgent,
  })
}

export const buildChannelChangedChange = (fromChannel, toChannel) => {
  if (!toChannel || fromChannel === toChannel) return null

  return buildChange(CHANGE_TYPE_CHANNEL_CHANGED, {
    from: pick(['id', 'name'], fromChannel),
    to: pick(['id', 'name'], toChannel),
  })
}

export const buildContactChange = (fromContactId, toContactId) => {
  if (!toContactId || fromContactId === toContactId) return null

  return buildChange(CHANGE_TYPE_CONTACT_CHANGED, {
    from: fromContactId,
    to: toContactId,
  })
}

export const buildConversationDeletedChange = () => {
  return buildChange(CHANGE_TYPE_CONVERSATION_DELETED)
}

export const buildFollowerAddedChange = agentId => {
  if (!agentId) return null

  return buildChange(CHANGE_TYPE_FOLLOWER_ADDED, {
    follower: agentId,
  })
}

export const buildFollowerRemovedChange = agentId => {
  if (!agentId) return null

  return buildChange(CHANGE_TYPE_FOLLOWER_REMOVED, {
    follower: agentId,
  })
}

export const buildConversationStarredChange = () => {
  return buildChange(CHANGE_TYPE_CONVERSATION_STARRED)
}

export const buildSubjectChangedChange = (from, to) => {
  if (from === to) return null

  return buildChange(CHANGE_TYPE_SUBJECT_CHANGED, {
    from,
    to,
  })
}

export const buildStateChanged = (fromState, toState) => {
  if (fromState === toState) return null

  return buildChange(CHANGE_TYPE_STATE_CHANGED, {
    from: fromState,
    to: toState,
  })
}

export const buildTaggedChange = tag => {
  if (!tag) return null

  return buildChange(CHANGE_TYPE_TAGGED, {
    tag,
  })
}

export const buildUntaggedChange = tag => {
  if (!tag) return null

  return buildChange(CHANGE_TYPE_UNTAGGED, {
    tag,
  })
}

export const buildTeamChangedChange = (fromTeam, toTeam) => {
  return buildChange(CHANGE_TYPE_TEAM_CHANGED, {
    from: fromTeam,
    to: toTeam,
  })
}

export const buildConversationRestoredChange = () => {
  return buildChange(CHANGE_TYPE_CONVERSATION_RESTORED)
}

export const buildSnoozedChange = (byAgentId, until) => {
  if (!byAgentId) return null

  return buildChange(CHANGE_TYPE_SNOOZED, {
    by: byAgentId,
    until,
  })
}

export const buildUnsnoozedChange = byAgentId => {
  if (!byAgentId) return null

  return buildChange(CHANGE_TYPE_UNSNOOZED, {
    by: byAgentId,
  })
}

export const buildConversationUnstarredChange = () => {
  return buildChange(CHANGE_TYPE_CONVERSATION_UNSTARRED)
}

export const buildCommentDeletedChange = () => {
  return buildChange(CHANGE_TYPE_COMMENT_DELETED)
}

export const buildCommentReactedChange = (reaction, isAdded) => {
  if (!reaction) return null

  return buildChange(CHANGE_TYPE_COMMENT_REACTED, {
    reaction,
    isAdded,
  })
}

export const buildMessage = (
  messageId,
  type,
  body,
  channelId,
  attachmentIds,
  toAuthors,
  ccAuthors,
  bccAuthors
) => {
  const bodyPlainText = stripHTML(body)
  const createdAt = new Date().toISOString()

  return {
    id: messageId || buildOptimisticId('Message'),
    bodyPlainText,
    editedAt: messageId ? new Date() : null,
    __typename: type,
    parts: [
      {
        type: 'TEXT',
        content: body,
      },
    ],
    summary: {
      bodyPlainText,
      body,
      createdAt,
      __typename: 'SummaryMessage',
    },
    body,
    channel: channelId,
    createdAt,
    attachments: attachmentIds,
    to: toAuthors,
    cc: ccAuthors,
    bcc: bccAuthors,
  }
}

export const buildMessageChange = message => {
  return {
    id: message.id,
    // eslint-disable-next-line no-underscore-dangle
    schema: message.__typename,
  }
}

const toById = array => {
  return array.reduce((result, item) => {
    // eslint-disable-next-line no-param-reassign
    result[item.id] = item
    return result
  }, {})
}

export const buildAttachment = attachment => {
  return {
    id: buildOptimisticId('Attachment'),
    ...attachment,
  }
}

export const buildAuthorsFromEmails = (
  getState,
  inputTo,
  inputCc,
  inputBcc
) => {
  const state = getState()
  const createdAt = new Date().toISOString()
  const contacts = []
  const lookup = {}
  const to = inputTo || []
  const cc = inputCc || []
  const bcc = inputBcc || []
  const allAddresses = [...to, ...cc, ...bcc]

  allAddresses.forEach(emailAddress => {
    const { email, name } = emailToEmailRecipientInput(emailAddress)
    let contact = lookup[email] || selectContactForEmail(state, email)
    const [firstName, ...restName] = (name || '').split(' ')
    const lastName = restName.join(' ')
    if (!contact) {
      contact = {
        id: buildOptimisticId('Contact'),
        email,
        name,
        firstName,
        lastName,
        createdAt,
        customFieldValues: {},
        role: 'CUSTOMER',
        __typename: 'Contact',
        companies: [],
        isPartiallyLoaded: true,
      }
      contacts.push(contact)
    }
    lookup[email] = contact
  })

  return {
    contacts,
    toAuthors: to.map(emailAddress => {
      const { email } = emailToEmailRecipientInput(emailAddress)
      return {
        id: lookup[email].id,
        schema: 'Contact',
      }
    }),
    ccAuthors: cc.map(emailAddress => {
      const { email } = emailToEmailRecipientInput(emailAddress)
      return {
        id: lookup[email].id,
        schema: 'Contact',
      }
    }),
    bccAuthors: bcc.map(emailAddress => {
      const { email } = emailToEmailRecipientInput(emailAddress)
      return {
        id: lookup[email].id,
        schema: 'Contact',
      }
    }),
  }
}

// same as Ticket::ASC_SORT_ORDER_FUTURE_ANCHOR on api
const ASC_SORT_ORDER_FUTURE_ANCHOR = new Date('2100-01-01T00:00:00.000Z')

export const calculateConversationOptimistic = async (
  getState,
  oldConversation,
  params,
  // Future proofing
  // eslint-disable-next-line no-unused-vars
  options
) => {
  const {
    conversationId,
    subject, // Change conversation subject
    state, // Change conversation state
    snoozeUntil, // Snooze until date (only used when state is SNOOZED)
    tagIdsToAdd, // Add specific tags
    tagIdsToRemove, // Remove specific tags
    removeAllTags, // Remove all tags
    agentId, // Assign to agent
    teamId, // Assign to team
    channelId: inputChannelId, // Move mailbox to different channel
    starred, // Change the conversation starred state
    reply, // Reply params
    forward, // Forward params
    note, // Note params
    messageId,
    reaction,
  } = params

  // Normalize ids here
  const channelId = inputChannelId ? getRawId(inputChannelId) : inputChannelId

  const store = getState()
  const currentAgentId = selectCurrentUserGlobalId(store)
  const tagsById = selectCurrentTagsById(store)
  const agentsById = selectCurrentAgentsById(store)
  const teamsById = selectCurrentTeamsById(store)
  const channelsById = selectCurrentChannelsById(store)
  const conversationEventGroups = selectTicketEventGroupsByConversationId(
    store,
    conversationId
  )
  const eventGroupId =
    !!reply || !!forward || !!note
      ? buildOptimisticId('EventGroupType')
      : conversationEventGroups.at(-1)?.id

  const reactionsByAgentId = selectReactionsByAgentIdForEventGroupId(
    store,
    eventGroupId
  )

  const {
    subject: oldSubject,
    state: oldState,
    snoozed: oldSnoozed,
    tags: oldTags,
    assigned: oldAssigned,
    channel: oldChannelId,
    starred: oldStarred,
  } = oldConversation
  const { until: oldSnoozeUntil } = oldSnoozed || {}
  const { agent: oldAgentId, team: oldTeamId } = oldAssigned || {}

  const conversation = { ...oldConversation }
  const changes = []
  const eventGroups = []
  const messages = []
  const contacts = []
  const attachments = []
  const actor = {
    id: getRawId(currentAgentId),
    schema: 'Agent',
  }

  if (subject !== undefined && oldSubject !== subject) {
    changes.push(buildSubjectChangedChange(oldSubject, subject))
    conversation.subject = subject
  }

  if (state) {
    if (
      state !== oldState ||
      (state === SNOOZED && snoozeUntil !== oldSnoozeUntil)
    ) {
      conversation.state = state
      conversation.stateUpdatedAt = new Date()
      conversation.updatedAt = new Date()
      conversation.systemUpdatedAt = new Date()

      if (oldState === SNOOZED && state !== SNOOZED) {
        conversation.snoozed = null
        changes.push(buildUnsnoozedChange(currentAgentId))
      } else if (oldState !== SNOOZED && state === SNOOZED) {
        conversation.snoozed = {
          by: {
            id: currentAgentId,
          },
          until: snoozeUntil,
        }
        changes.push(buildSnoozedChange(currentAgentId, snoozeUntil))
      } else if (
        !DELETED_EVENTS.includes(oldState) &&
        DELETED_EVENTS.includes(state)
      ) {
        changes.push(buildConversationDeletedChange())
      } else if (
        DELETED_EVENTS.includes(oldState) &&
        !DELETED_EVENTS.includes(state)
      ) {
        changes.push(buildConversationRestoredChange())
      } else {
        changes.push(buildStateChanged(oldState, state))
      }
    }
  }

  if (tagIdsToAdd) {
    const combinedTags = uniq([...oldTags, ...tagIdsToAdd])
    // Ensure  we dont mutate the original arrays
    if (!areArraysEqual([...oldTags].sort(), [...combinedTags].sort())) {
      conversation.updatedAt = new Date()
      conversation.systemUpdatedAt = new Date()
      conversation.tags = combinedTags

      tagIdsToAdd.forEach(tagId => {
        changes.push(buildTaggedChange(tagsById[tagId]))
      })
    }
  }

  if (tagIdsToRemove) {
    const combinedTags = conversation.tags.filter(
      tagId => !tagIdsToRemove.includes(tagId)
    )
    if (!areArraysEqual([...oldTags].sort(), [...combinedTags].sort())) {
      conversation.updatedAt = new Date()
      conversation.systemUpdatedAt = new Date()
      conversation.tags = combinedTags
      tagIdsToRemove.forEach(tagId => {
        changes.push(buildUntaggedChange(tagsById[tagId]))
      })
    }
  }

  if (removeAllTags && oldTags.length > 0) {
    conversation.tags.forEach(tagId => {
      changes.push(buildUntaggedChange(tagsById[tagId]))
    })
    conversation.updatedAt = new Date()
    conversation.systemUpdatedAt = new Date()
    conversation.tags = []
  }

  // We support unassigning an agent by sending in null
  if (agentId !== undefined || teamId !== undefined) {
    const normalizedAgentId = agentId ? getRawId(agentId) : agentId
    const normalizedTeamId = teamId ? getRawId(teamId) : teamId
    const normalizedOldAgentId = oldAgentId ? getRawId(oldAgentId) : oldAgentId
    const normalizedOldTeamId = oldTeamId ? getRawId(oldTeamId) : oldTeamId
    let hasAgentChanged = false
    let hasTeamChanged = false
    if (agentId !== undefined && normalizedOldAgentId !== normalizedAgentId) {
      hasAgentChanged = true
      changes.push(
        buildAgentChange(
          agentsById[normalizedOldAgentId],
          agentsById[normalizedAgentId]
        )
      )
    }
    if (teamId !== undefined && normalizedOldTeamId !== normalizedTeamId) {
      hasTeamChanged = true
      changes.push(
        buildTeamChangedChange(
          teamsById[normalizedOldTeamId],
          teamsById[normalizedTeamId]
        )
      )
    }

    if (!agentId && !teamId) {
      conversation.updatedAt = new Date()
      conversation.systemUpdatedAt = new Date()
      conversation.assigned = null
    } else if (hasAgentChanged || hasTeamChanged) {
      conversation.updatedAt = new Date()
      conversation.systemUpdatedAt = new Date()
      conversation.assigned = {
        agent: hasAgentChanged ? normalizedAgentId : normalizedOldAgentId,
        team: hasTeamChanged ? normalizedTeamId : normalizedOldTeamId,
        at: new Date().toISOString(),
      }
    }
  }

  if (channelId !== undefined && oldChannelId !== channelId) {
    conversation.updatedAt = new Date()
    conversation.systemUpdatedAt = new Date()
    conversation.channel = channelId
    // Deprecated, we should remove this eventually
    conversation.mailboxId = channelId
    changes.push(
      buildChannelChangedChange(
        channelsById[oldChannelId],
        channelsById[channelId]
      )
    )
  }

  if (starred !== undefined && oldStarred !== starred) {
    conversation.updatedAt = new Date()
    conversation.systemUpdatedAt = new Date()
    conversation.starred = starred
    if (starred) {
      changes.push(buildConversationStarredChange())
    } else {
      changes.push(buildConversationUnstarredChange())
    }
  }

  if (reaction && messageId) {
    const agentReactions = reactionsByAgentId[currentAgentId] || {}

    changes.push(buildCommentReactedChange(reaction, !agentReactions[reaction]))
  }

  const events = changes
    .filter(change => !!change)
    .map(change => buildEvent(eventGroupId, actor, change))

  if (forward || reply || note) {
    const {
      messageId: updateMessageId,
      to,
      cc,
      bcc,
      body,
      attachments: inputAttachments,
    } =
      note || reply || forward
    const {
      contacts: authorContacts,
      toAuthors,
      ccAuthors,
      bccAuthors,
    } = buildAuthorsFromEmails(getState, to, cc, bcc)
    authorContacts.forEach(c => contacts.push(c))

    const attachmentIds = []
    inputAttachments.forEach(attachment => {
      const optimistcAttachment = buildAttachment(attachment)
      attachments.push(optimistcAttachment)
      attachmentIds.push(optimistcAttachment.id)
    })

    let messageType = CHANGE_TYPE_REPLY_MESSAGE
    if (forward) {
      messageType = CHANGE_TYPE_FORWARDED_MESSAGE
    } else if (note) {
      messageType = CHANGE_TYPE_NOTE_MESSAGE
    }

    const message = buildMessage(
      updateMessageId,
      messageType,
      body,
      oldChannelId,
      attachmentIds,
      toAuthors,
      ccAuthors,
      bccAuthors
    )
    messages.push(message)
    if (!updateMessageId) {
      events.unshift(
        buildEvent(eventGroupId, actor, buildMessageChange(message))
      )

      eventGroups.push({
        id: eventGroupId,
        collapsed: false,
        fromMerge: false,
        isNote: !!note,
        isForward: !!forward,
        hasAttachments: attachmentIds.length > 0,
        conversationId,
        changesetId: uuidV4(),
        actor,
        summary: message.id,
        events: events.map(e => e.id),
        isFirstMessage: false,
        isLastMessage: true,
      })
    }

    conversation.updatedAt = new Date()
    conversation.systemUpdatedAt = new Date()

    if (forward || reply) {
      conversation.lastUnansweredUserMessageAt = new Date(
        ASC_SORT_ORDER_FUTURE_ANCHOR.getTime() + new Date().getTime()
      )
    }
  }

  return {
    entities: {
      conversation: {
        [conversationId]: conversation,
      },
      message: toById(messages),
      conversationEvent: toById(events),
      conversationEventGroup: toById(eventGroups),
      contacts: toById(contacts),
      attachment: toById(attachments),
    },
  }
}

export const createRemoveOptimisticEntitiesAdditonalActions = optimist => {
  return flatten(
    Object.keys(optimist.entities).map(entityType => {
      const entityById = optimist.entities[entityType]
      return Object.keys(entityById)
        .filter(eid => eid.startsWith('o:'))
        .map(eid => ({
          entityType,
          entityId: eid,
          stores: ['current'],
          operation: 'remove',
          phases: ['SUCCESS'],
        }))
    })
  )
}

export const createUpdateLastEventGroupAdditionalActions = eventGroups => {
  const additionalActions = []

  // When replying a new event group is created. This means that the current "last"
  // event group also needs to be updated to no longer be the last.
  eventGroups.forEach(eg => {
    if (eg.isLastMessage) {
      additionalActions.push({
        entityType: 'conversationEventGroup',
        entityId: eg.id,
        stores: ['current'],
        operation: 'update',
        phases: ['STARTED', 'SUCCESS'],
        entity: {
          isLastMessage: false,
        },
      })
    }
  })
  return additionalActions
}

// Event types linked to EventGroups that cannot be transfered to a different event group.
// Eg Reactions relate to a specific note and cannot be moved. A Conversation state event
// can however be moved to a different event group
const NON_TRANSFERABLE_GROUP_EVENTS = [
  CHANGE_TYPE_COMMENT_DELETED,
  CHANGE_TYPE_COMMENT_REACTED,
  CHANGE_TYPE_COMMENT_EDITED,
  CHANGE_TYPE_NOTE_MESSAGE,
]

export const createRemoveEventGroupAdditionalActions = (
  getState,
  conversationId,
  removeMessageId
) => {
  const store = getState()
  const currentAgentId = selectCurrentUserGlobalId(store)
  const eventGroups = selectTicketEventGroupsByConversationId(
    store,
    conversationId
  )
  const eventsByEventGroup = eventGroups.reduce((result, eventGroup) => {
    // eslint-disable-next-line no-param-reassign
    result[eventGroup.id] = selectConversationEventsByGroupId(
      store,
      eventGroup.id
    )
    return result
  }, {})

  const additionalActions = []

  const removeGroup = eventGroups.find(g => g.summary === removeMessageId)

  if (!removeGroup) return []

  // First optimistically remove the event group connected to our note
  additionalActions.push({
    entityType: 'conversationEventGroup',
    entityId: removeGroup.id,
    stores: ['current'],
    operation: 'remove',
    phases: ['STARTED'],
  })

  const removeEvent = eventsByEventGroup[removeGroup.id].find(
    e => e.change.id === removeMessageId
  )

  // Note remove the event connected to our note
  additionalActions.push({
    entityType: 'conversationEvent',
    entityId: removeEvent.id,
    stores: ['current'],
    operation: 'remove',
    phases: ['STARTED'],
  })

  // Finally remove the message
  additionalActions.push({
    entityType: 'message',
    entityId: removeMessageId,
    stores: ['current'],
    operation: 'remove',
    phases: ['STARTED'],
  })

  const removeIndex = eventGroups.findIndex(g => g.id === removeGroup.id)
  const previousIndex = removeIndex - 1
  if (previousIndex < 0) return []

  const previousGroup = eventGroups[previousIndex]

  // We dont want to move over the message event or any message related events
  // (reactions, edition etc)
  const transferredEvents = eventsByEventGroup[removeGroup.id]
    .filter(e => {
      return (
        e.id !== removeEvent.id &&
        !NON_TRANSFERABLE_GROUP_EVENTS.includes(
          // eslint-disable-next-line no-underscore-dangle
          e.change.__typename || e.change.schema
        )
      )
    })
    .map(e => e.id)

  const deleteChange = buildCommentDeletedChange()
  const optimisticDeleteEvent = buildEvent(
    previousGroup.id,
    { id: currentAgentId, schema: 'Agent' },
    deleteChange
  )

  additionalActions.push({
    entityType: 'conversationEvent',
    entityId: optimisticDeleteEvent.id,
    stores: ['current'],
    operation: 'update',
    phases: ['STARTED'],
    entity: optimisticDeleteEvent,
  })

  additionalActions.push({
    entityType: 'conversationEvent',
    entityId: optimisticDeleteEvent.id,
    stores: ['current'],
    operation: 'remove',
    phases: ['SUCCESS'],
  })

  // Next attach all transferrable events to the previous event group
  additionalActions.push({
    entityType: 'conversationEventGroup',
    entityId: previousGroup.id,
    stores: ['current'],
    operation: 'update',
    phases: ['STARTED'],
    entity: {
      events: [
        ...previousGroup.events,
        ...transferredEvents,
        optimisticDeleteEvent.id,
      ],
      isLastMessage: removeGroup.isLastMessage,
    },
  })

  // Next now update the event group id on the transferred events to the previous group
  transferredEvents.forEach(eid => {
    additionalActions.push({
      entityType: 'conversationEvent',
      entityId: eid,
      stores: ['current'],
      operation: 'update',
      phases: ['STARTED'],
      entity: {
        eventGroupId: previousGroup.id,
      },
    })
  })
  return additionalActions
}

export const buildConversationOptimistRequestOptions = async (
  getState,
  conversationId,
  params,
  options
) => {
  const state = getState()
  const isNewEventGroupType = params.note || params.reply || params.forward
  const isRemoveEventGroupType = params.removeMessageId
  const ticket = selectCurrentConversationById(state, conversationId)
  const eventGroups = selectTicketEventGroupsByConversationId(
    state,
    conversationId
  )
  const lastEventGroups = eventGroups.filter(eg => eg.isLastMessage)

  const optimist = await calculateConversationOptimistic(
    getState,
    ticket,
    params,
    options
  )
  const additionalActions = [
    ...createRemoveOptimisticEntitiesAdditonalActions(optimist),
    ...(isNewEventGroupType
      ? createUpdateLastEventGroupAdditionalActions(lastEventGroups)
      : []),
    ...(isRemoveEventGroupType
      ? createRemoveEventGroupAdditionalActions(
          getState,
          conversationId,
          params.removeMessageId
        )
      : []),
  ]

  return { optimist, additionalActions }
}

export const buildConversationOptimistDeleteOptions = async (
  getState,
  conversationId
) => {
  const ticketId = getRawId(conversationId)
  const state = getState()
  const eventGroups = selectConversationEventGroupsByConversationId(
    state,
    ticketId
  )
  const additionalActions = []
  eventGroups.forEach(eventGroup => {
    additionalActions.push({
      entityType: 'conversationEventGroup',
      entityId: eventGroup.id,
      stores: ['current'],
      operation: 'remove',
      phases: ['STARTED'],
    })
    additionalActions.push({
      entityType: 'message',
      entityId: eventGroup.summary,
      stores: ['current'],
      operation: 'remove',
      phases: ['STARTED'],
    })
    eventGroup.events.forEach(eventId => {
      additionalActions.push({
        entityType: 'conversationEvent',
        entityId: eventId,
        stores: ['current'],
        operation: 'remove',
        phases: ['STARTED'],
      })
    })
  })
  additionalActions.push({
    entityType: 'conversation',
    entityId: ticketId,
    stores: ['current'],
    operation: 'remove',
    phases: ['STARTED'],
  })

  return {
    additionalActions,
  }
}
